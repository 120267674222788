import React, { useCallback, useEffect, useState } from "react";
import formatDate, { dateAdd } from "includes/formatDate";
import useTheme from "hooks/useTheme";
import { useQueries } from "react-query";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import useToken from "classes/Accounts/hooks/useToken";
import StatsTopBar from "../StatsTopBar/StatsTopBar";
import StatsChartCanvas from "./StatsChartCanvas";
import PageLoad from "components/PageLoad/PageLoad";
import LoadCont from "components/LoadCont/LoadCont";
import Button from "components/Button/Button";
import NumberChart from "components/Charts/Number/NumberChart";
import { t } from "i18next";

const stats_url = "https://stats.onlyall.fr";

export default function StatsChart(props) {

	const { account } = useAccount();
	const { token } = useToken();

	const [graphDisp, setGraphDisp] = useState(false);
	const { theme } = useTheme();

	const date = new Date();
	const [Dates, setDates] = useState({
		start: dateAdd(date, { months: -1 }),
		end: dateAdd(date, { days: -1 })
	});
	const startDate = new Date(Dates.start);
	const endDate = new Date(Dates.end);

	const fetchFirstConnexion = () => {
		return genRequest("Stats/Activated_Account_In_Period_For_Company", { FromDate: Dates.start, ToDate: Dates.end, TargetCompanyId: account.CompanyId }, "POST");
	};

	const fetchTotalConnexion = () => {
		return genRequest("Stats/Total_Connexion_During_Period_For_Company", { FromDate: Dates.start, ToDate: Dates.end, TargetCompanyId: account.CompanyId }, "POST");
	}

	const fetchPostsOfTheDay = () => {
		return genRequest("Stats/Published_Post_Of_PostType_In_Period_For_Company", { FromDate: Dates.start, ToDate: Dates.end, TargetCompanyId: account.CompanyId }, "POST");
	}

	const queriesArr =
		[
			{
				queryKey: "FirstConnexion",
				queryFn: fetchFirstConnexion,
				retry: false
			},
			{
				queryKey: "TotalConnexion",
				queryFn: fetchTotalConnexion,
				retry: false
			},
			{
				queryKey: "PostsOfTheDay",
				queryFn: fetchPostsOfTheDay,
				retry: false
			}
		];

	const useQueriesWithRefetch = (...queries) => {
		const results = useQueries(...queries);

		const refetchAll = useCallback(() => {
			results.forEach(result => result.refetch());
		}, [results]);

		return {
			results,
			refetchAll,
		};
	};

	const { results, refetchAll } = useQueriesWithRefetch(queriesArr);

	const isLoadingAll = results.some((r) => r.isLoading);
	const isFetchingAll = results.some((r) => r.isFetching);

	const datas = results.map((r) => r.data);

	useEffect(() => {
		refetchAll();
	}, [Dates]);

	// function fetchStats() {
	// 	if (!props?.company?.Name)
	// 		return (Promise.reject(new Error("Bad Company Name provided")));
	// 	let encoded_name = noAccent(props.company.Name).replace(/\s/gi, "_");
	// 	let datas = {
	// 		CompanyName: encoded_name,
	// 		StartDate: formatDate(Dates.start, "Y/M/D"),
	// 		EndDate: formatDate(Dates.end, "Y/M/D"),
	// 		token: token
	// 	};
	// 	let params = new URLSearchParams(datas).toString();

	// 	let req = genRequest(
	// 		stats_url + "/?" + params,
	// 		null,
	// 		"get",
	// 		{
	// 			forceUrl: true
	// 		}
	// 	);
	// 	return (req);
	// }

	// const { isLoading, data } = useQuery(
	// 	["Stats", account.CompanyId, Dates.start, Dates.end],
	// 	fetchStats,
	// 	{
	// 		refetchOnWindowFocus: false,
	// 	}
	// );

	// const stats = data ? data : {};
	// const Weeks = data?.Weeks ? data.Weeks : [];

	if (isLoadingAll) return <PageLoad />;
	const [FirstConn, TotalConn, PostsOfTheDay] = datas;

	const transformData = ([FirstConn, TotalConn, PostsOfTheDay]) => {
		const getDateString = date => new Date(date).toLocaleDateString();

		const totalConnMap = TotalConn.reduce((acc, item) => {
			const key = getDateString(item.Value);
			(acc[key] = acc[key] || []).push(item.Key);
			return acc;
		}, {});

		const firstConnMap = FirstConn.reduce((acc, item) => {
			const key = getDateString(item.Value);
			(acc[key] = acc[key] || []).push(item.Key);
			return acc;
		}, {});

		const postsMap = PostsOfTheDay.reduce((acc, item) => {
			const key = getDateString(item.PublishedDate);
			(acc[key] = acc[key] || []).push(item.PostId);
			return acc;
		}, {});

		const result = [];
		for (let d = new Date(startDate); d < endDate; d.setDate(d.getDate() + 1)) {
			const dateStr = d.toLocaleDateString();
			result.push({
				Date: formatDate(d, "D/M/Y"),
				TotalConnexions: totalConnMap[dateStr] || [],
				FirstConnexions: firstConnMap[dateStr] || [],
				PostsOfTheDay: postsMap[dateStr] || []
			});
		}

		return result;
	};

	return (
		<div className="StatsChart">
			<StatsTopBar company={props?.company} dates={{ ...Dates }} setDates={setDates} />
			<div className="mt-2">
				<div className="mb-4">
					{
						isLoadingAll || isFetchingAll ?
							<PageLoad className="radius-2" style={{ height: "50vh" }} />
							:
							<StatsChartCanvas
								weeks={transformData(datas)}
								value={graphDisp}
								setDates={setDates}
								startDate={formatDate(Dates.start, "Y/M/D")}
								theme={theme}
								endDate={formatDate(Dates.end, "Y/M/D")}
							/>
					}
				</div>
				<div className="row mb-4">
					<CountButton
						isActive={graphDisp === "FirstConnexions"}
						onClick={() => setGraphDisp((graphDisp !== "FirstConnexions" ? "FirstConnexions" : false))}
						className={"mb-2"} title={t("Analytics.FIRST_CONN")} isLoading={isLoadingAll || isFetchingAll} value={FirstConn.length} color={"#B52653"} />
					<CountButton
						isActive={graphDisp === "TotalConnexions"}
						onClick={() => setGraphDisp((graphDisp !== "TotalConnexions" ? "TotalConnexions" : false))}
						className={"mb-2"} title={t("Analytics.TOTAL_CONN")} isLoading={isLoadingAll || isFetchingAll} value={TotalConn.length} color={"#1B4378"} />
					<CountButton
						isActive={graphDisp === "PostsOfTheDay"}
						onClick={() => setGraphDisp((graphDisp !== "PostsOfTheDay" ? "PostsOfTheDay" : false))}
						className={"mb-2"} title={t("Analytics.NEW_POSTS")} isLoading={isLoadingAll || isFetchingAll} value={PostsOfTheDay.length} color={"#DE742D"} />
				</div>

			</div>
		</div>
	);
}

function CountButton({ title, color, value, className, isLoading, isActive, onClick }) {
	return (
		<div className={"CountButton col-6 col-md-4 flex-fill text-center" + (className ? " " + className : "") + (isActive ? " active" : "")}>
			<div>{title}</div>
			{
				<Button onClick={onClick} className="btn text-center" style={isActive ? { backgroundColor: color } : {}}>
					{
						isLoading ?
							<LoadCont />
							:
							<NumberChart value={value} color={color} />
					}
				</Button>
			}
		</div>
	);
}

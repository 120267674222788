import useAccount from "classes/Accounts/hooks/useAccount";
import Storage from "classes/Storage/Storage";
import { useState } from "react";

function useTheme() {
	const storage = new Storage();
	const { account, getSettings } = useAccount();

	function applyThemes() {
		let bg_image = storage.getItem("comp_image");
		if (bg_image) {
			try {
				bg_image = JSON.parse(bg_image);
			} catch {
				bg_image = false;
			}
		}
		let body = document.querySelector("body");
		body.style.backgroundImage =
			"url(" +
			account?.CompanyBackground?.Url_Origin +
			(bg_image ? "," + bg_image : "") +
			")";
		// body.classList.add("theme-" + theme);
		let buttons = getSettings("ButtonsStyle");
		if (buttons) body.classList.add(buttons + "-buttons");
		let menu = getSettings("MenuStyle");
		if (menu) body.classList.add(menu + "-menu");
		else body.classList.add("default-menu");
	}

	const getTheme = () => {
		return "light";
	};

	const [theme, setTheme] = useState(getTheme());

	const saveTheme = (userTheme) => {
		document.querySelector("body").classList.add("changing-theme");
		// document.querySelector("body").classList.remove("theme-" + theme);
		localStorage.setItem("theme", userTheme);
		// document.querySelector("body").classList.add("theme-" + userTheme);
		setTimeout(() => {
			document.querySelector("body").classList.remove("changing-theme");
		}, 2000);
		return setTheme(userTheme);
	};

	return {
		setTheme: saveTheme,
		applyThemes,
		getTheme,
		theme,
	};
}

export default useTheme;

import React, { useEffect, useState, useRef } from "react";
import Konami from "konami";
import "./App.css";
import "./fonts.css";
import useToken from "classes/Accounts/hooks/useToken";
import ForgetPage from "Pages/Login/ForgetPage/ForgetPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import BrainWrapper from "components/BrainWrapper/Wrapper";
import { ToastContainer } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-toastify/dist/ReactToastify.min.css";
import LoginPage from "Pages/Login/LoginPage";
import Storage from "classes/Storage/Storage";
// import useMenu from "hooks/useMenu";
import useAccount from "classes/Accounts/hooks/useAccount";
import ReactModal from "react-modal";
import useEditing from "components/EditDrawer/useEditing";
import { AccountProvider } from "providers/AccountProvider";
import { FilterProvider } from "providers/FilterProvider";
import { PostProvider } from "providers/PostProvider";
import { installationPrompt } from "components-v2/PWA/pwaPrompt.component";
import { manualPrompt } from "components-v2/PWA/pwaManualPrompt.component";
import "components-v2/PWA/pwaCheck.styles.css";
import {
	APP_INSTALLED,
	BEFORE_INSTALL_PROMPT,
	INSTALLATION_MESSAGE,
	INSTALLATION_STATUS,
	getDeviceInfo,
} from "includes/pwa_helpers";
import { UAParser } from "ua-parser-js";
import OneSignal from "react-onesignal";
import { RedirectNotifs } from "includes/redirectNotif";
// import CreatePage from "Pages/Create/Create";

// eslint-disable-next-line
const easterEgg = new Konami("https://shimos.fr/?boot");

export default function App() {
	const { token } = useToken();
	const storage = new Storage();
	const { account, setSettings, getSettings } = useAccount();
	const [isInitialized, setInitialized] = useState(false);

	const { device, pwa } = getDeviceInfo(new UAParser());
	const deferredPrompt = useRef(null);

	const appInstalled = () => {
		localStorage.setItem(INSTALLATION_STATUS, INSTALLATION_MESSAGE);
		deferredPrompt.current = null;
	};
	const doNotShowAgain = (close) => {
		appInstalled();
		close();
	};
	const alreadyInstalled = doNotShowAgain;
	const onInstall = (close) => {
		if (deferredPrompt.current) {
			deferredPrompt?.current.prompt();
			// Wait for the user to respond to the prompt
			let status = false;
			deferredPrompt.current.userChoice.then((choiceResult) => {
				status = choiceResult.outcome === "accepted";
			});
			if (status) {
				appInstalled();
				deferredPrompt.current = null;
			}
			close();
		}
	};

	const loadOneSignal = async () => {
		const prod = "0bd2f946-10a2-4c61-bbe0-f1efe5d984d5";
		const local = "e8d5f665-3a97-4499-9338-f293f1e46cd0";
		if (
			"serviceWorker" in navigator &&
			!navigator.serviceWorker.controller
		) {
			navigator.serviceWorker.register("/OneSignalSDKWorker.js");
		}
		OneSignal.init({
			appId: prod,
			serviceWorkerPath: "OneSignalSDKWorker.js",
		}).then(() => {
			OneSignal.Slidedown.promptPush();
		});
		OneSignal.Notifications.addEventListener(
			"permissionChange",
			(IsUserValidated) => {
				if (IsUserValidated) {
					OneSignal.login(account?.AspNetUserId)
						.then(() => {
							console.log(
								`User logged in with ID: ${account?.AspNetUserId}`
							);
						})
						.catch((error) => {
							console.error(
								"Error during OneSignal login:",
								error
							);
						});
				} else {
					OneSignal.logout();
				}
			}
		);

		OneSignal.Notifications.addEventListener(
			"foregroundWillDisplay",
			(event) => {
				const targetPage =
					event?.notification?.additionalData?.TargetPage;
				// alert(JSON.stringify(event?.notification?.additionalData));
				if (targetPage) {
					if (targetPage === "OpenPostPage") {
						window.location.href =
							event?.notification?.additionalData.redirectLink;
					} else {
						window.location.href = RedirectNotifs[targetPage];
					}
					// window.location.href = targetPage;
				}
			}
		);
	};
	useEffect(() => {
		if (token && !isInitialized) {
			loadOneSignal();
		}
	}, [token]);

	// PWA Installation Prompt

	useEffect(() => {
		window.addEventListener(BEFORE_INSTALL_PROMPT, (e) => {
			e.preventDefault();
			deferredPrompt.current = e;
		});
		window.addEventListener(APP_INSTALLED, appInstalled);
	}, []);

	useEffect(() => {
		const isIosStandalone = window.navigator.standalone === true;

		const isInstallable =
			device.isMobile || (device.isMobile && !pwa.isStandalone(window));
		const isInstalled = localStorage.getItem(INSTALLATION_STATUS);
		setTimeout(() => {
			if (!isIosStandalone) {
				if (isInstallable && !isInstalled)
					if (deferredPrompt.current) {
						installationPrompt({
							doNotShowAgain,
							onInstall,
						});
					} else {
						manualPrompt({
							doNotShowAgain,
							alreadyInstalled,
							isIOS: device.isIOS,
							isAndroid: device.isAndroid,
						});
					}
			}
		}, 1e3);
	}, [device.isAndroid, device.isIOS, deferredPrompt.current]);

	const [langCode, setLang] = useState(getNavigatorLanguage());
	useEditing(false);

	function changeLang(code) {
		if (account?.CompanyId) setSettings("Language", code);
		else {
			localStorage.setItem("lang-code", code);
			window.location.reload();
		}
	}

	function getNavigatorLanguage() {
		let lang = navigator.language || navigator.userLanguage;

		if (account) {
			let from_settings = getSettings("Language");
			if (from_settings) return from_settings;
		}
		let from_local = localStorage.getItem("lang-code");
		if (from_local) return from_local;
		if (!lang) return "en-EN";
		if (lang.indexOf("-") === -1) lang = lang + "-" + lang.toUpperCase();
		return lang;
	}

	const get_load_img = () => storage.getItem("comp_image");

	if (!token)
		return (
			<Router>
				<Switch>
					{/* <Route exact path={"/create"}>
						<CreatePage setLangCode={setLang}/>
					</Route> */}
					<Route exact path="/forget">
						<ForgetPage setLangCode={setLang} />
					</Route>
					<Route exact path={["/:accountId", "*"]}>
						<LoginPage setLangCode={setLang} />
					</Route>
				</Switch>
			</Router>
		);

	return (
		<AccountProvider>
			<PostProvider>
				<FilterProvider>
					{/* <ReactPWAInstallProvider enableLogging> */}
					<div
						ref={(ref) => ReactModal.setAppElement(ref)}
						className="wrapper"
						style={{
							backgroundImage: `url(${
								get_load_img()?.Url_Origin
							})`,
						}}
					>
						<ToastContainer
							position="top-right"
							autoClose={2000}
							hideProgressBar={false}
							newestOnTop={false}
							closeOnClick={true}
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
						/>
						<BrainWrapper
							code={langCode}
							setLangCode={changeLang}
							// getMenuItems={getMenuItems}
							// getMenuItem={getMenuItem}
						/>
					</div>
					{/* </ReactPWAInstallProvider> */}
				</FilterProvider>
			</PostProvider>
		</AccountProvider>
	);
}

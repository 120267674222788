import Button from "components/Button/Button"
import { Icon } from "@iconify/react"
import { confirmAlert } from "react-confirm-alert"
import { t } from "i18next"

// This Prompt is for devices which supports auto installation on PWA
export const installationPrompt = async ({ doNotShowAgain, onInstall }) => {
    confirmAlert({
        overlayClassName: "pwa_overlay",
        customUI: ({ onClose: close }) => {
            return <>
                <div className="pwaModalContainer">
                    <div className="flex flex-row justify-between gap-6">
                        <div className="flex items-start -mr-6">
                            <img style={{ width: "118px" }} src="/assets/logos/logo.png" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <h4 className="text-[18px] font-bold">
                                {t('PWAMsg.Title')}
                            </h4>
                            <p className="text-[14px]">
                                {t('PWAMsg.Message')}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <Button
                            className="gray"
                            onClick={() => close()}
                        >
                            {t('PWAMsg.AlreadyInstalled')}
                            <Icon
                                icon="fluent:checkbox-checked-20-filled"
                                className="ml-2 text-blue-500"
                                width={20}
                            />
                        </Button>
                        <Button
                            primary={true}
                            onClick={() => onInstall(close)}
                        >
                            {t('PWAMsg.btnInstall')}
                            <Icon
                                icon="ic:baseline-install-mobile"
                                className="ml-2 text-green-500"
                                width={20}
                            />
                        </Button>
                    </div>
                </div>
            </>
        },
    })
}
import React, { forwardRef, useEffect, useState } from "react";

import { Paper } from "@material-ui/core";
import MaterialTable, { MTableBodyRow, MTableCell } from "material-table";
import "./Table.css";
import "./TableHead.css";
import "./TableBody.css";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import i18n, { t } from "i18next";
import noAccent from "includes/no_accents";
import TableToolbar from "./TableToolbar/TableToolbar";
import { useQuery, useQueryClient } from "react-query";

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => (
		<ArrowDownward {...props} ref={ref} />
	)),
	ThirdStateCheck: forwardRef((props, ref) => (
		<Remove {...props} ref={ref} />
	)),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const genTrads = () => ({
	body: {
		emptyDataSourceMessage: t("MaterialTable.body.emptyDataSourceMessage"),
		addTooltip: t("MaterialTable.body.addTooltip"),
		deleteTooltip: t("MaterialTable.body.deleteTooltip"),
		editTooltip: t("MaterialTable.body.editTooltip"),
		filterRow: {
			filterTooltip: t("MaterialTable.body.filterRow.filterTooltip"),
		},
		editRow: {
			deleteText: t("MaterialTable.body.editRow.deleteText"),
			cancelTooltip: t("MaterialTable.body.editRow.cancelTooltip"),
			saveTooltip: t("MaterialTable.body.editRow.saveTooltip"),
		},
	},
	grouping: {
		placeholder: t("MaterialTable.grouping.placeholder"),
		groupedBy: t("MaterialTable.grouping.groupedBy"),
	},
	header: {
		actions: t("MaterialTable.header.actions"),
	},
	pagination: {
		labelDisplayedRows: t("MaterialTable.pagination.labelDisplayedRows"),
		labelRowsSelect: t("MaterialTable.pagination.labelRowsSelect"),
		labelRowsPerPage: t("MaterialTable.pagination.labelRowsPerPage"),
		firstAriaLabel: t("MaterialTable.pagination.firstAriaLabel"),
		firstTooltip: t("MaterialTable.pagination.firstTooltip"),
		previousAriaLabel: t("MaterialTable.pagination.previousAriaLabel"),
		previousTooltip: t("MaterialTable.pagination.previousTooltip"),
		nextAriaLabel: t("MaterialTable.pagination.nextAriaLabel"),
		nextTooltip: t("MaterialTable.pagination.nextTooltip"),
		lastAriaLabel: t("MaterialTable.pagination.lastAriaLabel"),
		lastTooltip: t("MaterialTable.pagination.lastTooltip"),
	},
	toolbar: {
		addRemoveColumns: t("MaterialTable.toolbar.addRemoveColumns"),
		nRowsSelected: t("MaterialTable.toolbar.nRowsSelected"),
		showColumnsTitle: t("MaterialTable.toolbar.showColumnsTitle"),
		showColumnsAriaLabel: t("MaterialTable.toolbar.showColumnsAriaLabel"),
		exportTitle: t("MaterialTable.toolbar.exportTitle"),
		exportAriaLabel: t("MaterialTable.toolbar.exportAriaLabel"),
		exportName: t("MaterialTable.toolbar.exportName"),
		searchTooltip: t("MaterialTable.toolbar.searchTooltip"),
		searchPlaceholder: t("MaterialTable.toolbar.searchPlaceholder"),
	},
});

export default function Table(props) {
	let className = "Table";
	const isInitied = React.useRef(false);
	const [tableData, setTableDate] = useState([]);
	const ref = React.useRef();
	const QCL = useQueryClient();

	React.useEffect(() => {
		if (!isInitied.current) {
			if (props.onMount) props.onMount();
			else {
				if (props.selectionKey)
					QCL.setQueriesData(props.selectionKey, null);
			}
			isInitied.current = true;
		}

		return () => {
			isInitied.current = false;
		};
	}, []);
	if (props.className) className += " " + props.className;
	if (props.options?.selection) className += " selectable";

	const mouseDown = (e) => {
		if (props?.options?.selection && e.target.type === "checkbox")
			window.SCROLLTABLE_LASTMD = Date.now();
	};

	const mouseUp = () => {
		if (props?.options?.selection) window.SCROLLTABLE_LASTMD = false;
	};

	const selectRow = (target) => {
		if (target?.tagName === "TD") {
			target.parentElement.querySelector("input").click();
			return true;
		}
		return false;
	};

	const clearSelection = () => {
		if (!ref?.current) return false;
		ref.current.onAllSelected(false);
	};

	useEffect(() => {
		async function getData() {

			if (props.filter) {
				const dataFiltered = await props.filter(props.data);
				setTableDate(dataFiltered);
			} else {
				setTableDate(props.data);
			}
		};

		getData();
		return () => {
			setTableDate([]);
		}
	}, [props.data, props.filter]);

	return (
		<MaterialTable
			tableRef={ref}
			components={{
				Container: (cprops) => (
					<Paper
						{...cprops}
						onMouseDown={mouseDown}
						onMouseUp={mouseUp}
						onMouseLeave={mouseUp}
						className={className}
					/>
				),
				Row: (cprops) => {
					const lastMd = React.useRef(false);
					let classes = "";
					if (props.options?.rowClassName)
						classes = props.options.rowClassName(cprops.data);
					if (props.activeRowId && props.rowIdKey)
						classes +=
							cprops.data[props.rowIdKey] === props.activeRowId
								? " active"
								: "";
					return (
						<MTableBodyRow
							className={classes}
							onMouseOver={(e) => {
								if (
									window.SCROLLTABLE_LASTMD &&
									window.SCROLLTABLE_LASTMD !== lastMd.current
								)
									if (selectRow(e.target))
										lastMd.current =
											window.SCROLLTABLE_LASTMD;
							}}
							{...cprops}
						/>
					);
				},
				Cell: (cprops) => {
					return (
						<MTableCell
							{...cprops}
							className={cprops?.columnDef?.className}
						/>
					);
				},
				...props.overloads,
				Toolbar: (cprops) => {
					if (props.overloads?.Toolbar)
						return props.overloads.Toolbar(cprops, {
							buttons: props.actions,
							handleDelete: props.handleDelete,
							clearSelection: clearSelection,
						});
					return (
						<TableToolbar
							{...cprops}
							buttons={props.actions}
							handleDelete={props.handleDelete}
							clearSelection={clearSelection}
						/>
					);
				},
			}}
			localization={i18n.language !== "en-EN" ? genTrads() : {}}
			onSelectionChange={(res) => {
				if (props.onSelectionChange) props.onSelectionChange(res);
				if (props.selectionKey)
					QCL.setQueryData(props.selectionKey, res);
			}}
			{...props}
			data={tableData}
			icons={{ ...tableIcons, ...props.icons }}
			options={{
				headerStyle: { position: "sticky", top: 0 },
				maxBodyHeight: "1000px",
				overflowY: "initial",
				showTitle: props.title?.length > 0,
				thirdSortClick: false,
				emptyRowsWhenPaging: false,
				pageSize: 25,
				pageSizeOptions: [5, 10, 25, 50, 100],
				sorting: true,
				draggable: false,
				...props.options,
			}}
			onChangePage={
				props.withPagination && props.onChangePage
					? (page) => {
						props.onChangePage(page);
					}
					: undefined
			}
			onChangeRowsPerPage={
				props.withPagination && props.onChangeRowsPerPage
					? (pageSize) => {
						props.onChangeRowsPerPage(pageSize);
					}
					: undefined
			}
		/>
	);
}

export const itemsSort = (a, b, key) => {
	// if (typeof a[key] === "string" && typeof b[key] === "string") {
	// 	let txt_a = noAccent(a[key]).toLowerCase();
	// 	let txt_b = noAccent(b[key]).toLowerCase();
	// 	if (txt_a === txt_b) return 0;
	// 	return txt_a < txt_b ? -1 : 1;
	// }
	// return a[key] < b[key] ? -1 : 1;
	let txt_a = noAccent(a[key]).toLowerCase();
	let txt_b = noAccent(b[key]).toLowerCase();
	if (txt_a === txt_b) return 0;
	return txt_a < txt_b ? -1 : 1;
};

export function AsyncTable(props) {
	const { isLoading, data } = useQuery(
		props.queryKey,
		props.queryFn,
		props.queryOpt
	);

	if (isLoading) return <div>{t("Commons.LOADING")}</div>;

	return <Table {...props} data={data} />;
}

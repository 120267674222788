import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { t } from "i18next";
import Button from "components/Button/Button";

export default function ChartEndStep(props) {

	let chart = props.object;

	function genPreviewText() {
		if (chart.isDraft())
			return (
				<div>
					<div>{t("Charts.CHART_DRAFTED")}</div>
					<div><Button className="btn-primary" onClick={props.dispDrafts}>{t("Commons.SHOW_DRAFTS")}</Button></div>
				</div>
			);
		return (
			<div>
				{t("Charts.CHART_SENDED")}
				<div className="closeBtn" onClick={props.close}><span className="icons icon-close f-30"></span></div>
			</div>
		);
	}

	return (
		<div className="text-center inner-step-cont">
			{genPreviewText()}
			<div style={{ fontSize: "3.75em", color: "green" }}><FontAwesomeIcon icon={faCheckCircle} /></div>
		</div>
	);
}

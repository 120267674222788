import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import CompanyChanger from "components/CompanyChanger/CompanyChanger";
import PlateformChanger from "components/PlateformChanger/PlateformChanger";

export default function AdminMenuHead(props) {
	const { isSup, getSettings } = useAccount();

	return (
		<div
			className={`AdminMenuHead${props.className ? " " + props.className : ""
				}`}
		>
			{isSup("EloAdmin") >= 0 && getSettings("CompanyChanger") && (
				<div className="mb-2">
					<CompanyChanger />
				</div>
			)}
			<PlateformChanger
				platform={props.platform}
				setPlatform={props.setPlatform}
				isReduced={props.isReduced}
			/>
		</div>
	);
}

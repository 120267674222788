import React from "react";
import genRequest from "includes/request";
import "./SiteTile.css";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import { AsyncUsersList } from "components/Users/UsersList/UsersList";
import { t } from "i18next";
import SiteLogo from "../SiteLogo/SiteLogo";
import SaveFavSites from "components/Sites/SaveFavSites/SaveFavSites.component";
import { useAccountContext } from "providers/AccountProvider";
import clsx from "clsx";
import { FILES_URL } from "includes/paths";

export default function SiteTile(props) {
	const { site, logoSite, TotalEmployes } = props;
	const { userOrAdmin } = useAccountContext();
	const [isHover, setIsHover] = React.useState(false);
	const logoUrl = `${FILES_URL}${logoSite}`;
	const parts = logoUrl.split('/');
	const fileName = parts.pop();
	const encodedFileName = encodeURIComponent(fileName);
	const logoEncoded = [...parts, encodedFileName].join('/');
	return (
		<div className={clsx("SiteTile tile", isHover ? "isHover" : "")} onClick={props.onClick} onMouseEnter={() => setIsHover(!isHover)} onMouseLeave={() => setIsHover(!isHover)}>
			{/* <SiteLogo
				className={"me-3"}
				imgAlt={[site.Name, site.Town, site.Country].join("-")}
				siteId={site.SiteId}
				companyId={site.CompanyId}
			/> */}
			<div
				className={
					"site-logo align-items-center justify-content-center me-2 w-100"
				}
			>
				<img src={logoEncoded} alt={[site.Name, site.Town, site.Country].join("-")} className="me-3" />
			</div>
			{userOrAdmin === "admin" && (
				<SaveFavSites
					className={"unstyled ms-auto"}
					siteId={site.SiteId}
				/>
			)}
			<div className="d-flex flex-column w-100 overflow-hidden">
				<div className="title font-bold mb-1">{site.Name}</div>
				<div className="location">
					{[site.Town, site.Country].join(" - ")}
				</div>
				<div className="mt-auto">
					<div className="nbr-member-txt">
						{t("Sites.MEMBERS", { count: TotalEmployes })}
					</div>
					{/* <InfosFetcher
						className="nbr-member-txt"
						queryKey={["Employes", "EmployeOfSite", site.SiteId]}
						queryFn={() =>
							genRequest("Employes/EmployeOfSite/" + site.SiteId)
						}
						treat={(infos) => {
							return infos.length
								? t("Sites.MEMBERS", { count: infos?.length })
								: false;
						}}
					/> */}
					{/* <AsyncUsersList
						queryKey={["Employes", "SortedMinimalContactEmployesOfSite", site.SiteId]}
						queryFn={() =>
							genRequest("Employes/SortedMinimalContactEmployesOfSite?SiteId=" + site.SiteId)
						}
						max={10}
						width={20}
					/> */}
				</div>
			</div>
		</div>
	);
}

import { FILES_URL } from "includes/paths";
import genRequest from "includes/request";
import { t } from "i18next";
import React from "react";
import ImageSender from "./ImageSender/ImageSender";

function DefaultImagesTab(props) {

	function sendDefaultImage({ companyId, typeId, typeName, file, setProgressEvent }) {
		let req = genRequest(
			"Documents/DefaultImageFromString?postTypeId=" + typeId + "&companyId=" + companyId,
			{ DocString: file.data?.split(",")[1] },
			"POST",
			{
				onUploadProgress: (e) => {
					setProgressEvent({
						title: t("Images.IMAGE_SEND"),
						event: e
					});
				}
			}
		).then(() => {
			return (FILES_URL + companyId + "/Default" + typeName + "Image.png?" + Date.now());
		});
		return (req);
	}

	return (
		<>
			<DefaultImageSender delete={false} className="mb-4" sendImage={sendDefaultImage} title={<h5>{t("Pages.NEWS")}</h5>} companyId={props.companyId} typeId={1} typeName="News" />
			<DefaultImageSender delete={false} className="mb-4" sendImage={sendDefaultImage} title={<h5>{t("Pages.OFFERS")}</h5>} companyId={props.companyId} typeId={4} typeName="Ad" />
			<DefaultImageSender delete={false} className="mb-4" sendImage={sendDefaultImage} title={<h5>{t("Pages.PRO_OFFERS")}</h5>} companyId={props.companyId} typeId={14} typeName="ProAd" />
			<DefaultImageSender delete={false} className="mb-4" sendImage={sendDefaultImage} title={<h5>{t("Pages.CAREERS")}</h5>} companyId={props.companyId} typeId={5} typeName="Career" />
			<DefaultImageSender delete={false} sendImage={sendDefaultImage} title={<h5>{t("Pages.ARTICLES")}</h5>} companyId={props.companyId} typeId={3} typeName="Enterprise_Article" />
		</>
	);
}

const DefaultImageSender = (props) => (
	<ImageSender
		isDefault={true}
		description={t("Images.RECOMMENDED_SIZE", { width: 1400, height: 1100 })}
		imgUrl={FILES_URL + props.companyId + "/Default" + props.typeName + "Image.png?" + Date.now()}
		{...props}
	/>
);

export default DefaultImagesTab;

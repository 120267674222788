import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import "./DocumentTile.css";
import genRequest from "includes/request";
import { t } from "i18next";
import TagsList, { QueryTagsList } from "components/Tags/TagsList/TagsList";
import { humanFileSize } from "includes/documents_treat";
import useUnread from "classes/Accounts/hooks/useUnread";
import { NewLabel } from "components/Tags/Label/TagLabel";
import SaveDocBtn from "../SaveDocBtn/SaveDocBtn";

export default function DocumentTile(props) {
	const { isReaded, setAsReaded } = useUnread();
	let doc = props.doc;
	let color = props.color;

	function handleClick(doc) {
		incrementViews().then(() => {
			setAsReaded("NotViewedDocUtils", doc.DocumentId);
		});
		if (props.onClick)
			props.onClick(doc);
	}

	const incrementViews = () => {
		let req = genRequest(
			"Documents/IncrementView",
			doc.DocumentId,
			"put"
		).then((resp) => {
			return (resp);
		}).catch(() => {
		});
		return (req);
	};

	if (!doc)
		return (false);

	let readed_doc = isReaded("NotViewedDocUtils", doc.DocumentId);

	return (
		<div
			className={"tile document-tile flex-row align-items-center" + (props.className ? " " + props.className : "")}
			onClick={() => handleClick(doc)}
		>
			<a
				href={props.onClick ? "#" + doc.DocumentId : doc.Url_Origin}
				onClick={(e) => { e.preventDefault(); handleClick(doc); }}
				target={!props.onClick ? "_blank" : ""}
				rel="noopener noreferrer"
				className="w-100 d-flex flex-row align-items-center">
				<div>
					<FontAwesomeIcon color={color} size="2x" icon={faFilePdf} />
				</div>
				<div className="h-100 w-100 d-flex flex-column align-items-between ms-2 ps-1 justify-content-between">
					<div className="d-flex justify-content-between align-items-center doc-title">
						<div className="d-flex align-items-center justify-content-between w-100">
							<div className="d-flex align-items-center">
								{!readed_doc && <NewLabel className="me-1" />}
								<div className="font-bold">{doc.Name}</div>
							</div>
							{props.size && <div className="">{typeof props.size === "number" ? humanFileSize(props.size) : props.size}</div>}
						</div>
						<div className="d-flex">
							{
								props.handleDelete &&
								<FontAwesomeIcon
									icon={faTrashAlt}
									color={"red"}
									className={"me-2 del-doc-icon"}
									title={t("Docs.DEL_DOC_TITLE")}
									onClick={props.handleDelete}
								/>
							}
							{
								(props.handleSave !== false) &&
								<SaveDocBtn className={"ms-2"} docId={doc.DocumentId} />
							}
						</div>
					</div>
					{
						(props.categories !== false) &&
						<QueryTagsList
							className="mt-1"
							queryKey={["Categories", "Document", doc.DocumentId]}
							queryFn={() => genRequest("Categories/Document/" + doc.DocumentId)}
						/>
					}
				</div>
			</a>
		</div>
	);
}

import { useEffect, useState } from "react";
import Storage from "classes/Storage/Storage";

function useToken() {
	const storage = new Storage(null, null, localStorage);
	const sess_storage = new Storage(null, null, sessionStorage);

	const getToken = () => {
		if (sess_storage.getItem("token")) {
			const userToken = sess_storage.getItem("token");
			return userToken?.access_token;
		}
		const userToken = storage.getItem("token");
		return userToken?.access_token;
	};

	const [token, setToken] = useState(getToken());

	const saveToken = (userToken) => {
		storage.setItem("token", userToken);
		setToken(userToken.token);
	};

	return {
		setToken: saveToken,
		token,
	};
}

export default useToken;

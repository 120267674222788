import React from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { WelcomePhrases } from "./Phrases";
import formatDate, { dateAdd } from "includes/formatDate";
import { t } from "i18next";
import "./Welcome.css";

const Welcome = React.memo(({ type, forceAccount, refreshTime, className }) => {
	const { account } = useAccount();
	const { LastConnexion } = account;

	function checkDate(min, max) {
		let cur_date = new Date();
		let last_conn = new Date(getLastConn());
		let min_date = (min ? dateAdd(cur_date, min) : false);
		let max_date = (max ? dateAdd(cur_date, max) : false);

		if ((min_date && max_date && min_date >= last_conn && max_date <= last_conn) ||
			(min_date && !max_date && min_date >= last_conn) ||
			(!min_date && max_date && max_date <= last_conn) ||
			(!min_date && !max_date))
			return (true);
		return (false);
	}

	function getDispAccount() {
		if (forceAccount)
			return (forceAccount);
		return (account);
	}

	function getLastConn() {
		if (forceAccount?.LastConnexion)
			return (forceAccount.LastConnexion);
		return (LastConnexion ? LastConnexion : false);
	}

	function getFromStorage() {
		try {
			let date = Date.now();
			let last = sessionStorage.getItem("welcome");
			if (!last)
				return (false);
			last = JSON.parse(last);
			if (last.time > date - (refreshTime ? refreshTime : 1000 * 30) && (last.phrase.indexOf("undefined") === -1) && last.employeId === account.EmployesId)
				return (last.phrase);
			return (false);
		} catch (e) {
			return (false);
		}
	}

	function getWelcome() {
		let groups = WelcomePhrases;
		let cur_date = new Date();
		let ret = [];
		let phrase = "";
		let last_conn = getLastConn();
		let disp_type = (typeof type !== "undefined" ? type : false);

		let last = getFromStorage();
		if (last && !forceAccount)
			return (last);
		if (!last_conn) // First Connexion
			disp_type = "first_conn";
		for (let group of groups) {
			if (
				(disp_type && disp_type !== group.type)
				|| (group.forceType && !disp_type)
			)
				continue;
			if (checkDate(group.min_date, group.max_date)) {
				let list = group.list;

				for (let elem of list) {
					let cur_time = formatDate(cur_date, "h:m");

					if (
						(!elem.min_time || elem.min_time < cur_time)
						&& (!elem.max_time || elem.max_time > cur_time)
					)
						ret.push(elem.phrase);
				}
			}
		}
		phrase = ret[Math.round(Math.random() * (ret.length - 1))];
		phrase = t(`Welcome.${phrase}`, { ...getDispAccount() });
		sessionStorage.setItem("welcome", JSON.stringify({
			time: Date.now(),
			phrase,
			employeId: account.EmployesId
		}));
		return (phrase);
	}

	return (
		<div className={"Welcome" + (className ? " " + className : "")}>
			{getWelcome()}
		</div>
	);

});
Welcome.displayName = "Welcome";

export default Welcome;

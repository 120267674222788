import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Storage from "classes/Storage/Storage";
import useAccounts from "classes/Accounts/hooks/useAccounts";
import "./LoginPage.css";
import LoginForm from "./LoginForm/LoginForm";
import AccountsList from "classes/Accounts/components/AccountsList/AccountsList";
import useToken from "classes/Accounts/hooks/useToken";
import useAccount from "classes/Accounts/hooks/useAccount";
import AccountObject from "classes/Accounts/Account/AccountObject";
import CGU from "components/CGU/CGU";
import LoginFooter from "./LoginFooter/LoginFooter";
import formatDate from "includes/formatDate";
import genRequest from "includes/request";
// import Button from "components/Button/Button";

export default function LoginPage(props) {
	let { accountId } = useParams();
	const [, setLastUpdate] = useState(Date.now());
	const storage = new Storage();
	const { getAccount, getAccounts, saveAccount } = useAccounts();
	const { setAccount } = useAccount();
	const [isLogingIn, setIsLogingIn] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [BGImage, setBGImage] = useState(getLocalImages("comp_image"));
	const [Logo, setLogo] = useState(getLocalImages("comp_logo"));
	const [CGUCallbacks, setCGUCallbacks] = useState(false);
	const { setToken } = useToken();
	const [IsInitied, setIsInitied] = useState(false);
	const [Current, setCurrent] = useState(initCurrentFromParam());

	function initCurrentFromParam() {
		if (IsInitied) return false;
		setIsInitied(true);
		let account = getAccount(parseInt(accountId));
		if (account) loadCurrent(account, true);
		return account;
	}

	function refreshList() {
		setLastUpdate(Date.now());
	}

	// handle saved account connect
	function loadCurrent(account, noSave) {
		if (!account) return false;
		setBGImage(account.CompanyBackground);
		setLogo(account.CompanyLogo);
		if (!noSave) setCurrent(account);
		if (account.Token) {
			let account_obj = new AccountObject(account);
			if (!account_obj.isTokenValid()) return account;
			setIsLogingIn(true);
			handleLogin(account_obj, true);
		}
		return account;
	}



	// Login from LoginForm
	function handleLogin(account, save_pass) {
		setToken(account.Token);
		let prom = account.fetch(save_pass).then((resp) => {
			let values = account.values(save_pass);
			values.Token_Regeneration = false;
			changeBGImage(resp.CompanyBackground);
			changeLogo(resp.CompanyLogo);
			saveAccount(values);
			setCurrent(resp);
			if (!resp.FirstConnexion || !resp.LastConnexion) showCGU(values);
			else initSession(values);
		});
		return prom;
	}

	function showCGU(account) {
		setCGUCallbacks({
			handleAccept: () => {
				account.FirstConnexion = formatDate();
				genRequest("Me", account, "put").then(() => {
					initSession(account);
				});
			},
			handleReject: () => {
				storage.removeItem("token");
				setCurrent(false);
				setIsConnected(false);
				setIsLogingIn(false);
				setCGUCallbacks(false);
			},
		});
	}

	function initSession(account) {
		setAccount(account);
		setIsConnected(true);
		setIsLogingIn(false);
		setTimeout(() => {
			window.location.assign("/");
		}, 1000);
	}

	function changeBGImage(imageObject) {
		storage.setItem("comp_image", imageObject);
		setBGImage(imageObject);
	}

	function changeLogo(imageObject) {
		storage.setItem("comp_logo", imageObject);
		setLogo(imageObject);
	}

	function getLocalImages(key) {
		let image = false;
		try {
			image = storage.getItem(key);
		} catch (e) {
			return false;
		}
		return image;
	}

	let accounts = getAccounts();

	return (
		<div
			className={"LoginPage" + (CGUCallbacks ? " CGU" : "")}
			style={{
				backgroundImage: BGImage?.Url_Origin
					? "url(" + BGImage.Url_Origin + ")"
					: "",
			}}
		>
			<div className="login-wrapper">
				<div
					className="logo-cont"
					style={{
						backgroundImage: Logo?.Url_Origin
							? "url(" + Logo.Url_Origin + ")"
							: "",
					}}
				></div>
				<AccountsList
					className="mb-2"
					accounts={accounts}
					active={Current}
					onClick={loadCurrent}
					refreshList={refreshList}
				/>
				{(!accounts?.length || Current) &&
					!isConnected &&
					!CGUCallbacks && (
						<LoginForm
							onCancel={() => setCurrent(false)}
							user={Current}
							onConnected={handleLogin}
							isLogingIn={isLogingIn}
							setIsLogingIn={setIsLogingIn}
							setCurrent={setCurrent}
						/>
					)}
				{CGUCallbacks && <CGU {...CGUCallbacks} />}
			</div>
			{/* <div className="d-flex gap-2 join-cont align-items-center">
				<div>Rejoignez OnlyAll aujourd'hui !</div>
				<div>
					<Button onClick={() => {
						window.location.replace("/create")
					}}>Créer</Button>
				</div>
			</div> */}
			<LoginFooter setLangCode={props.setLangCode} />
		</div>
	);
}

export const RedirectNotifs = {
	Alert: "/alerts",
	About: "/articles",
	Indicators: "/charts",
	DocUtils: "/documents",
	ProfessionalAnnouncement: "/pro_offers",
	ClassifiedAd: "/offers",
	Career: "/careers",
	Agenda: "/agenda",
	Profil: "/myonlyall",
	MyNews: "/news",
	Forms: "/forms",
};

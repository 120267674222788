import React from "react";
import { useQuery } from "react-query";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";
import "./SiteLogo.css";
import useCompanyLogo from "../../../../hooks/useCompanyLogo";
import LoadCont from "components/LoadCont/LoadCont";

function SiteLogo(props) {
	const { imgAlt, siteId, companyId, className, url, theme } = props;
	const { height, width } = props;
	const typeId = theme === "dark" ? 17 : 10;

	const fetchSiteLogo = async () => {
		try {
			const resp = await genRequest(`Documents/AllDocumentsOfSiteTyped/${siteId}/${typeId}`);
			if (!resp?.length) {
				return null;
			}
			return documents_treat(resp, "LastChanged", 1)[0];
		} catch (error) {
			return null;
		}
	};

	const {
		isLoading: isSiteLoading,
		data: siteLogoData,
	} = useQuery(
		["site-logo", siteId, typeId],
		fetchSiteLogo,
		{
			staleTime: 1000 * 60 * 60,
			retry: 1,
			enabled: !!siteId && typeof url === "undefined",
		}
	);

	const {
		isLoading: isCompanyLoading,
		data: companyLogoData,
	} = useCompanyLogo(companyId, theme);

	if (isSiteLoading || isCompanyLoading)
		return (
			<div
				className={
					"site-logo align-items-center justify-content-center me-2 w-100" +
					(className ? " " + className : "")
				}
				style={{
					width,
					height,
				}}
			>
				<LoadCont />
			</div>
		);

	let img_url = siteLogoData?.Url_Origin || companyLogoData?.Url_Origin || "assets/logos/logo.png";
	if (url) img_url = url;

	return (
		<div className={"site-logo" + (className ? " " + className : "")}>
			<img
				loading="lazy"
				alt={"Logo" + (imgAlt ? " " + imgAlt : "")}
				src={img_url}
				height={height}
				width={width}
			/>
		</div>
	);
}

export default SiteLogo;

import React, { useEffect, useState } from "react";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import genRequest from "includes/request";
import { useInfiniteQuery, useQuery } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import SitePreview from "./components/SitePreview/SitePreview";
import "./SitesPage.css";
import noAccent from "includes/no_accents";
import SiteEdit from "./components/SiteEdit/SiteEdit";
import SiteObject from "classes/Sites/SiteObject";
import useAccount from "classes/Accounts/hooks/useAccount";
import Map from "./components/Map/Map";
import { SiteMapPopup } from "./components/Map/Map";
import AppTemplate from "classes/AppTemplate/AppTemplate";
import useTemplate from "hooks/useTemplate";
import { t } from "i18next";
import PageLoad from "components/PageLoad/PageLoad";
import useEditing from "components/EditDrawer/useEditing";
import SitesList from "./components/SitesList";
import useDisplay from "hooks/useDisplay";
import { usePostContext } from "providers/PostProvider";
import { useFilterContext } from "providers/FilterProvider";

function SitesPage(props) {
	const { account, isAdmin } = useAccount();
	const { template } = useTemplate();
	const [curSite, setCurSite] = useState(false);
	const [editObj, setEditObj] = useState(false);
	const [Sites, setSites] = useState(false);
	const [searchRes, setSearchRes] = useState(false);
	const [dispMap, setDispMap] = useState(false);
	const { setEditing } = useEditing();
	const { display, setDisplay } = useDisplay("tiles");
	const { isMenuOpen, setIsMenuOpen } = props;
	const { postToCreate, setPostToCreate } = usePostContext();
	const [originalTableElements, setOriginalTableElements] = useState([]);
	const [tableElements, setTableElements] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const pageSizeSite = 20;

	useEffect(() => {
		if (postToCreate) {
			setEditObj(postToCreate);
		}
	}, [postToCreate]);

	function handleClosePreview() {
		setCurSite(false);
	}

	function handleCloseEdit() {
		setCurSite(false);
		setEditObj(false);
		setEditing(false);
		setPostToCreate(null);
	}

	function handleSearch(val) {
		if (display !== "table") {
			setSearchQuery(val.trim());
			return;
		}
		if (!val?.length)
			return (setTableElements(originalTableElements));
		if (tableElements.length === 0)
			return (false);
		let reg = new RegExp(noAccent(val), "gi");
		let res = tableElements.filter((Site) => {
			if (
				noAccent(Site.Name).search(reg) !== -1
				|| noAccent(Site.Country).search(reg) !== -1
				|| noAccent(Site.Town).search(reg) !== -1
			)
				return (Site);
			return (false);
		});
		setSearchRes(res);
		setTableElements(res);

		return (res);
	}

	function handleAdd() {
		let site = new SiteObject({
			CompanyId: account.CompanyId,
			Template: new AppTemplate(null, template?.values())
		});
		setEditObj(site);
		setEditing(true);
	}

	function handleModify() {
		let site = new SiteObject(curSite);
		site.fetchTemplate().then((resp) => {
			if (!site.template()) {
				let temp = new AppTemplate(null, template?.values());
				site.template(temp);
			}
			let adminId = resp?.Values?.Company?.AdminSiteId;
			site.adminId(adminId);
			setEditObj(site);
		});
		setEditing(true);
	}

	const {
		data,
		isLoading,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery(
		["Sites", "SitesOfCompany", account.CompanyId, pageSizeSite, searchQuery],
		async ({ pageParam = 1, queryKey }) => {
			const [, , companyId, pageSize, searchParam] = queryKey;
			let url = `Sites/PagedSitesOfCompany?CompanyId=${companyId}&PageNumber=${pageParam}&PageSize=${pageSize}`;
			if (searchParam) {
				url += `&SearchParam=${encodeURIComponent(searchParam)}`;
			}
			const resp = await genRequest(url);
			let sorted = resp.sort((a, b) => {
				let a_name = [a.Site.Name, a.Site.Town, a.Site.Country]
					.filter((c) => c)
					.join(" - ");
				let b_name = [b.Site.Name, b.Site.Town, b.Site.Country]
					.filter((c) => c)
					.join(" - ");
				return noAccent(a_name.toLowerCase()) < noAccent(b_name.toLowerCase())
					? -1
					: 1;
			});
			return {
				items: sorted,
				nextPage: resp.length === pageSize ? pageParam + 1 : undefined,
			};
		},
		{
			getNextPageParam: (lastPage) => lastPage.nextPage,
			enabled: !editObj && display !== "table",
		}
	);


	const { data: tableData, isLoading: isLoadingTable } = useQuery(
		["Sites", "SitesOfCompany", account.CompanyId],
		async () => {
			const resp = await genRequest(
				`Sites/SitesOfCompany/${account.CompanyId}`
			);
			let sorted = resp.sort((a, b) => {
				const aName = [a.Name, a.Town, a.Country].filter(Boolean).join(" - ");
				const bName = [b.Name, b.Town, b.Country].filter(Boolean).join(" - ");
				return noAccent(aName.toLowerCase()) < noAccent(bName.toLowerCase()) ? -1 : 1;
			});
			return sorted;
		},
		{ enabled: !editObj && display === "table" }
	);


	const allSites = data ? data.pages.flatMap((page) => page.items) : [];

	useEffect(() => {
		if (!isLoadingTable && tableData) {
			setTableElements(tableData);
			setOriginalTableElements(tableData);
		}
	}, [isLoadingTable, tableData]);


	if (isLoading && isLoadingTable)
		return (<PageLoad />);

	let elems = allSites;

	// setSites(tableData);

	// if (searchRes) {
	// 	tableElements = searchRes;
	// }

	// 	elems = searchRes;

	return (
		<div className="SitesPage">
			<PageHeadBar
				typeId={"siteTypeId"}
				title="Sites"
				search={handleSearch}
				siteSelect={false}
				addTitle={t("Sites.ADD_BTN")}
				handleAdd={isAdmin() ? handleAdd : false}
				searchValue={searchQuery}
				buttons={[{
					forceTitle: true,
					title: <FontAwesomeIcon icon={faMapMarkedAlt} />,
					classes: "btn-map btn" + (dispMap ? " btn-primary" : ""),
					onClick: () => setDispMap(!dispMap)
				}]}
				display={display}
				setDisplay={setDisplay}
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
			/>
			{
				dispMap ?
					<MapCont elems={tableElements} searching={searchRes?.length > 0 ? searchRes : false} />
					:
					<>
						<SitesList
							tableItems={tableElements}
							items={elems}
							curSite={curSite}
							handleItemClick={setCurSite}
							display={display}
							fetchNextPage={fetchNextPage}
							hasMore={hasNextPage}
							isFetchingNextPage={isFetchingNextPage}
						/>
						{
							curSite &&
							<PreviewDrawer
								child={SitePreview}
								fullScreen={true}
								onClose={handleClosePreview}
								isOpen={curSite ? true : false}
								childProps={{
									site: curSite,
									handleModify: handleModify
								}}
							/>
						}
					</>
			}
			{editObj &&
				<SiteEdit
					onClose={handleCloseEdit}
					site={editObj}
					isOpen={editObj}
					companyId={account.CompanyId}
					account={props.account}
					title={t("Sites." + (!editObj?.siteId() ? "ADD_TITLE" : "MODIFY_TITLE"))}
				/>
			}
		</div>
	);
}

function MapCont({ elems, searching }) {
	const { account } = useAccount();

	let points = elems.map((a) => {
		let loc = a.Localisation;
		let point = false;
		if (loc) {
			loc = loc.split(",");
			if (loc.length < 2)
				loc = false;
			else {
				point = {
					lat: parseFloat(loc[0]),
					lng: parseFloat(loc[1]),
					popUpProps: {
						site: a
					}
				};
			}
		}
		return (point);
	}).filter(a => a);

	let center = points.map(a => {
		if (a?.popUpProps?.site.SiteId === account.SiteId)
			return ({ lat: a.lat, lng: a.lng });
		return (false);
	}).filter(a => a)[0];

	let flyTo = false;
	if (searching && points?.length) {
		flyTo = points[0];
		flyTo.zoom = 16;
	} else {
		flyTo = {
			lat: 45.7686597,
			lng: 4.8299098,
			zoom: 2.5,
			isDefault: true
		};
	}

	return (
		<div className="MapCont">
			<Map
				points={points}
				zoom={center ? 4 : 2.5}
				flyTo={flyTo}
				center={center ? center : { lat: 45.7686597, lng: 4.8299098 }}
				updateOnRefocus={false}
				popUpChild={SiteMapPopup}
			/>
		</div>
	);
}

export default SitesPage;

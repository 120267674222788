import formatDate from "includes/formatDate";

import React, { Component, useEffect, useState } from "react";
import DocsTable from "../DocsTable/DocsTable";
import DocsTiles from "../DocsTiles/DocsTiles";
// import "./DocsList.css";

const DocsList = (props) => {
	const [docs, setDocs] = useState(props.docs ? props.docs : []);
	const [displayed, setDisplayed] = useState([]);
	const [dispIndex, setDispIndex] = useState(0);
	const [dispStep] = useState(30);
	const [hasMore, setHasMore] = useState(true);
	const [dispFavs, setDispFavs] = useState(false);
	const [dispCats, setDispCats] = useState([]);
	const [curDoc, setCurDoc] = useState(false);

	const sortDocs = (sort_by, asc_order) => {
		if (!sort_by) sort_by = "PublishedDate";
		let documents = docs.sort((a, b) => {
			if (a[sort_by] < b[sort_by]) return asc_order ? -1 : 1;
			return asc_order ? 1 : -1;
		});
		setDocs(documents);
	}

	const checkState = () => {
		// let curDispState = this.props.state;

		// if ((!this.props?.typeId || (post.PostTypeId === this.props.typeId))
		// 	&& ((curDispState === "waiting" && post.ValidationModeration === false && post.Draft === false && !post.ValidationModeration)
		// 	|| (curDispState === "moderated" && post.ModerationComment && post.ValidationModeration === false && post.Draft === true)
		// 	|| (curDispState === "published" && post.ValidationModeration === true && post.Draft === false)
		// 	|| (curDispState === "drafts" && post.Draft === true && !post.ModerationComment && !post.ValidationModeration))
		// )
		// 	return (true);
		// return (false);
		return true;
	}

	const checkDate = (doc) => {
		let date = formatDate();
		return (
			date >= doc.PublishedDate ||
			props.state !== "published" ||
			props.isAdmin ||
			doc.EmployesId === props.account?.EmployesId
		);
	}

	const checkSite = (doc) => {
		// let siteId = curSite?.value;
		// if (siteId) if (doc.SiteId !== siteId) return false;
		return true;
	}

	const getNextDocs = (max_limit) => {
		let disp_count = 0;
		let total_count = 0;
		let items = [];
		let documents = docs;

		if (props.search) documents = props.searchRes;

		for (
			let x = dispIndex;
			disp_count < dispStep &&
			x < documents?.length &&
			(!max_limit || (max_limit && disp_count < max_limit));
			x++
		) {
			let doc = documents[x];
			total_count++;
			if (
				checkDate(doc) &&
				checkState(doc) &&
				checkSite(doc)
			) {
				disp_count++;
				items.push(doc);
			}
		}


		setDisplayed(displayed.concat(items));
		setDispIndex(dispIndex + total_count);
		setHasMore(
			(!max_limit && disp_count < dispStep) ||
				docs?.length < dispStep
				? false
				: true
		);
		return items;
	}

	useEffect(() => {
		sortDocs();
		getNextDocs();
	}, [props.docs]);

	let childProps = {
		curDoc: props.curDoc,
		displayed: displayed,
		hasMore: hasMore,
		handleDocClick: props.handleDocClick,
		getNextItems: getNextDocs,
		dispCats: props.dispCats,
		saveds: props.Saveds,
		searching: props.searching,
		state: props.state,
	};

	return (
		<div className={"DocsList d-flex overflow-hidden h-100"}>
			{props.display === "table" ? (
				<DocsTable
					itemsLength={displayed?.length}
					{...childProps}
					displayed={props.docs}
				/>
			) : (
				<DocsTiles
					itemsLength={displayed?.length}
					{...childProps}
				/>
			)}
		</div>
	)
}

export default DocsList;
import React, { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "i18next";
import LoadCont from "components/LoadCont/LoadCont";
import SiteTile from "./SiteTile";

export default function SitesTiles(props) {
	let ref = useRef();

	// function handleScroll() {
	// 	let el = ref.current?.el;
	// 	if (el.scrollHeight <= el.clientHeight && props.hasMore)
	// 		props.getNextItems();
	// }

	// React.useEffect(() => {
	// 	let el = ref.current?.el;

	// 	handleScroll();
	// 	el.addEventListener("mousewheel", handleScroll);
	// 	return () => {
	// 		el.removeEventListener("mousewheel", handleScroll);
	// 	};
	// }, []);

	const computedHasMore = props.isFetchingNextPage
		? true
		: ((!props.displayed || props.displayed.length === 0) ? false : props.hasMore);

	const noResults = !props.isFetchingNextPage && (!props.displayed || props.displayed.length === 0);

	return (
		<div>
			<InfiniteScroll
				className="d-flex flex-wrap overflow-x-hidden"
				ref={ref}
				dataLength={props.displayed?.length} //This is important field to render the next data
				next={props.getNextItems}
				hasMore={computedHasMore}
				loader={
					<LoadCont className="w-100" text={t("Commons.LOADING")} />
				}
				height={"100%"}
				endMessage={
					<div className="w-100 infinite-scroll-end-msg mt-4 mb-4">
						<p style={{ textAlign: "center" }}>
							<b>
								{noResults ? t("Posts.NO_RESULT") : t("Posts.LIST_END")}
							</b>
						</p>
					</div>
				}
			>
				{props.displayed.map((a) => {
					return (
						<SiteTile
							onClick={() => props.handleItemClick(a.Site)}
							key={a.SiteId}
							site={a.Site}
							logoSite={a.LogoOfSite}
							TotalEmployes={a.TotalEmployes}
						/>
					);
				})}
			</InfiniteScroll>
		</div>
	);
}

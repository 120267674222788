import React, { useState } from "react";
import { motion } from "framer-motion";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCog,
	faEllipsisH,
	faPowerOff,
} from "@fortawesome/free-solid-svg-icons";

import Account from "../Account/Account";
import useAccount from "../hooks/useAccount";
import useToken from "../hooks/useToken";
import UserImage from "components/User/Image/UserImage";
import { t } from "i18next";
import SwitchInput from "components/Inputs/Switch/SwitchInput";
import Storage from "classes/Storage/Storage";
import { CustomPageLink } from "components/Menu/components/PageLink/PageLink";
import Button from "components/Button/Button";
import { useMediaQuery } from "react-responsive";

function AccountsMenu(props) {
	const { token } = useToken();
	const { account } = useAccount();
	const storage = new Storage();
	const [isOpen, setIsOpen] = useState(false);
	const [CurAccount, setCurAccount] = useState(false);
	const { accountClick, handleRemove } = props;
	const { isReduced, setIsReduced } = props;

	React.useEffect(() => {
		if (isOpen) {
			let nav = document.querySelector(".menu-nav");
			if (nav) nav.scrollTop = 10e5;
		}
	}, []);

	function handleClick(account) {
		setCurAccount(account);
		accountClick(account);
	}

	function newAccount() {
		setCurAccount("new_account");
		accountClick("new_account");
	}

	function getAccounts() {
		let accounts = [];
		try {
			const accounts_string = storage.getItem("Accounts");
			if (accounts_string?.length) {
				accounts = accounts_string;
				if (accounts)
					accounts = accounts.sort((a, b) => {
						let a_full = a.FirstName + " " + a.LastName;
						let b_full = b.FirstName + " " + b.LastName;
						if (a_full < b_full) return -1;
						return 1;
					});
			}
		} catch (e) {
			return accounts;
		}
		return accounts;
	}

	let accounts = props.accounts ? props.accounts : getAccounts();
	if (!accounts && !token) return false;

	function OnesignalLogout() {
		window.OneSignalDeferred.push(async function (OneSignal) {
			await OneSignal.logout();
		});
	}

	function toggleAccountsMenu() {
		setIsOpen(!isOpen);
		setIsReduced(
			{
				state: isReduced.state,
				hover: true,
			},
			true
		);
	}

	function handleMouseLeave() {
		setIsOpen(false);
	}

	function MultiAccount() {
		let theme = props.theme;
		let cur_theme = props.theme.theme;
		return (
			<div
				className={
					"accounts-menu px-2" + (isOpen ? " open pt-2 ps-2 m-1" : "")
				}
				onMouseLeave={handleMouseLeave}
				onClick={(e) => e.stopPropagation()}
			>
				<div className={isOpen ? "ms-1" : ""}>
					<CurAccountElem
						OnesignalLogout={OnesignalLogout}
						user={account}
						isActive={true}
						toggleMenu={toggleAccountsMenu}
						handlePageLinks={props.handlePageLinks}
						isOpen={isOpen}
					/>
				</div>
				<div
					className={
						"account-menu-body" +
						(isOpen ? " open d-block" : " d-none")
					}
				>
					<div className="account-menu-links">
						<div
							key="profile"
							onClick={() => props.handlePageLinks("/profile")}
						>
							<CustomPageLink
								activeOnlyWhenExact={true}
								to="/myonlyall"
							>
								{t("Pages.MY_SPACE")}
							</CustomPageLink>
						</div>
						<div
							key="settings"
							onClick={() => props.handlePageLinks("/settings")}
						>
							<CustomPageLink
								activeOnlyWhenExact={true}
								to="/settings"
							>
								{t("Pages.MY_SETTINGS")}
							</CustomPageLink>
						</div>
						{/* <div className="theme-switch-cont mb-2">
							<SwitchInput
								label={t("Theme.DARK_THEME")}
								checked={
									!cur_theme || cur_theme === "light"
										? false
										: true
								}
								onChange={(elem) => theme.switchTheme(elem)}
							/>
						</div> */}
					</div>
					<div className="accounts-list">
						{accounts.map((user, b) => {
							if (user.EmployesId === account.EmployesId)
								return false;
							return (
								<AccountElem
									key={user.EmployesId || "fallback-" + b}
									user={user}
									onClick={() => {
										sessionStorage.clear();
										window.location.assign(
											"/" + user.EmployesId
										);
									}}
								/>
							);
						})}
					</div>
					<div className="copyrights">
						<a
							href="https://onlyall.fr"
							rel="noreferrer"
							target={"_blank"}
						>
							v{process.env.REACT_APP_VERSION} - OnlyAll
						</a>{" "}
						©{" "}
						<a
							href="https://joonsoft.fr"
							rel="noreferrer"
							target={"_blank"}
						>
							JoonSoft
						</a>
					</div>
				</div>
			</div>
		);
	}

	if (!token) {
		let contAnim = {
			hidden: { opacity: 0 },
			show: {
				opacity: 1,
				transition: {
					when: "beforeChildren",
					staggerChildren: 0.1,
				},
			},
		};

		const itemAnim = {
			hidden: { y: -10, opacity: 0 },
			show: {
				y: 0,
				opacity: 1,
				transition: {
					ease: "easeInOut",
				},
			},
		};

		return (
			<div>
				<motion.div
					variants={contAnim}
					initial="hidden"
					animate="show"
					className={`accounts-list ${
						CurAccount ? "active-cur" : ""
					}`}
				>
					{accounts.map((account) => {
						return (
							<motion.div
								className="account-item-anim"
								variants={itemAnim}
								key={account.EmployesId}
							>
								<Account
									handleRemove={() =>
										handleRemove(account.Login)
									}
									isActive={
										CurAccount?.EmployesId ===
										account.EmployesId
									}
									datas={account}
									onClick={handleClick}
								/>
							</motion.div>
						);
					})}
				</motion.div>
				<div className="text-center" onClick={newAccount}>
					<a href="/#add-account" role="button">
						{t("Accounts.ADD_ACCOUNT")}
					</a>
				</div>
			</div>
		);
	} else return <MultiAccount />;
}

function CurAccountElem({
	user,
	isActive,
	isOpen,
	onClick,
	handlePageLinks,
	toggleMenu,
	OnesignalLogout,
}) {
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });
	const storage = new Storage();
	const openProfile = (e) => {
		e.preventDefault();
		e.stopPropagation();
		handlePageLinks("/myonlyall");
	};

	return (
		<div
			className={
				"account-menu d-flex align-items-center justify-content-center d-flex gap-2 w-100 cur-account-menu"
			}
			onClick={onClick}
		>
			<div
				key="myonlyall"
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					handlePageLinks("/myonlyall");
				}}
			>
				<CustomPageLink activeOnlyWhenExact={true} to="/myonlyall">
					<UserImage
						companyId={user.CompanyId}
						employeId={user.EmployesId}
						title={user.FirstName + " " + user.LastName}
					/>
				</CustomPageLink>
			</div>
			<div className="account-menu-infos w-100">
				<div className="d-flex justify-content-between">
					<CustomPageLink
						activeOnlyWhenExact={true}
						to="/myonlyall"
						onClick={openProfile}
					>
						<div className="w-auto">
							<div>{t("Commons.WELCOME")},</div>
							{user.FirstName +
								(!isActive ? " " + user.LastName : "")}
						</div>
					</CustomPageLink>
					{!isOpen && (
						<Button
							className="w-auto btn-transp btn-h-bg"
							style={{ fontSize: "12px" }}
							title="Menu du compte"
							onClick={toggleMenu}
						>
							<span className="icons icon-settings f-15"></span>
							{/* <FontAwesomeIcon icon={faCog} color="grey" /> */}
						</Button>
					)}
				</div>
				<div className="btn-disconnect mt-1">
					<FontAwesomeIcon className="me-1" icon={faPowerOff} />
					<a
						href="/#disconnect"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							OnesignalLogout();
							sessionStorage.clear();
							storage.removeItem("token");
							window.location.assign("/");
						}}
					>
						{t("Accounts.DISCONNECT")}
					</a>
				</div>
			</div>
			{!isMobile && (
				<Button
					className="p-1 show-account-menu-btn"
					onClick={toggleMenu}
				>
					<FontAwesomeIcon icon={faEllipsisH} />
				</Button>
			)}
		</div>
	);
}
function AccountElem({ user, isActive, isOpen, onClick, toggleMenu }) {
	let comp_name = user.Company?.Name;

	return (
		<div
			className={
				"account-menu d-flex align-items-center justify-content-center d-flex gap-2 w-100 cursor-pointer p-1"
			}
			onClick={onClick}
		>
			<UserImage
				companyId={user.CompanyId}
				employeId={user.EmployesId}
				title={user.FirstName + " " + user.LastName}
			/>
			<div className="account-menu-infos w-100">
				<div className="d-flex justify-content-between">
					<div className="w-auto">
						{user.FirstName +
							(!isActive ? " " + user.LastName : "")}
						{comp_name?.length > 0 && (
							<div className="company-name">{comp_name}</div>
						)}
					</div>
					{isActive && !isOpen && (
						<Button
							className="w-auto btn-transp btn-h-bg"
							style={{ fontSize: "12px" }}
							title="Menu du compte"
							onClick={toggleMenu}
						>
							<FontAwesomeIcon icon={faCog} color="grey" />
						</Button>
					)}
				</div>
			</div>
		</div>
	);
}

export default AccountsMenu;

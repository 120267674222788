import React, { Component, useEffect, useState } from "react";
import DocPreview from "../DocPreview/DocPreview";
import "./DocumentsPage.css";
import { t } from "i18next";
import noAccent from "includes/no_accents";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import DocumentObject from "classes/Documents/DocumentObject";
import DocEditDrawer from "../DocEditDrawer/DocEditDrawer";
import DocsList from "../DocsList/DocsList";
import HighlightedCategories from "components/HighlightedCategories/HighlightedCategories";
import { usePostContext } from "providers/PostProvider";
import { useFilterContext } from "providers/FilterProvider";

const DocumentsPage = (props) => {
	const [docs, setDocs] = useState(props.docs ? props.docs : []);
	const [displayed, setDisplayed] = useState([]);
	const [dispIndex, setDispIndex] = useState(0);
	const [dispStep] = useState(30);
	const [hasMore, setHasMore] = useState(true);
	const [curDoc, setCurDoc] = useState(false);
	const [editDoc, setEditDoc] = useState(false);
	const [searchResults, setSearchResults] = useState(false);
	const [search, setSearch] = useState("");
	const [dispCats, setDispCats] = useState([]);
	const [dispFavs, setDispFavs] = useState(false);
	const [shouldRefresh, setShouldRefresh] = useState(false);
	const { setPostToCreate } = usePostContext();
	const { setCategoryIdsArray, setCategoriesSelected } = useFilterContext();

	const sortDocs = (sort_by, asc_order) => {
		if (!sort_by) sort_by = "LastChanged";
		let documents = docs.sort((a, b) => {
			if (a[sort_by] < b[sort_by]) return asc_order ? -1 : 1;
			return asc_order ? 1 : -1;
		});
		setDocs(documents);
	}

	const selectCategory = (cat) => {
		let categories = [...dispCats];
		let cat_id = cat.id || cat.CategoryId;
		let check = dispCats.indexOf(cat_id);
		if (check === -1 && cat_id !== void (0)) categories.push(cat_id);
		else
			categories = categories
				.map((a) => {
					if (a === cat_id) return false;
					return a;
				})
				.filter((a) => a);
		setDispCats(categories);
		setCategoryIdsArray(categories);
	}

	const resetCatsFilter = () => {
		setDispCats([]);
		setCategoryIdsArray([]);
		setCategoriesSelected([]);
	}

	const getDocs = (name_filter) => {
		let filter_reg = new RegExp(noAccent(name_filter), "gi");
		let ret = docs.filter((doc) => {
			if (!name_filter || noAccent(doc.Name).search(filter_reg) !== -1)
				return doc;
			return false;
		}).filter((a) => a);
		return ret;
	}

	const handleDocClick = (doc) => {
		setCurDoc(doc);
	}

	const handleSearch = (val) => {
		let docs = val?.length ? getDocs(val) : false;

		setSearch(val);
		setSearchResults(docs);
		setDisplayed([]);
		setHasMore(true);
		setDispIndex(0);
	}

	const handleDelete = (doc) => {
		if (props.handleDelete) props.handleDelete(doc);
	}

	const handleAdd = () => {
		let obj = new DocumentObject({
			CompanyId: props.account.CompanyId,
			SiteId: props.account.SiteId,
			DocTypeId: 4,
			IsShareable: props.getAppFunction(
				"DocumentsPage.AllowSharing"
			),
			account: props.account,
		});
		setEditDoc(obj);
		setCurDoc(false);
		props.setEditing(true);
	}

	const handleModify = (doc) => {
		let obj = new DocumentObject({
			...doc,
			CompanyId: props.account.CompanyId,
			account: props.account,
		});

		setEditDoc(obj);
		setCurDoc(false);
		props.setEditing(true);
	}

	const handleCloseEdit = () => {
		setEditDoc(false);
		props.setEditing(false);
		setPostToCreate(null);
	}

	useEffect(() => {
		sortDocs();
	}, []);

	useEffect(() => {
		if (shouldRefresh && props.refreshList) {
			props.refreshList();
			setShouldRefresh(false);
		}
	}, [shouldRefresh]);

	var SiteSelectRender = false;

	if (typeof props.isAdmin === 'function') {
		SiteSelectRender = props.isAdmin() ? props.setSite : false;
		// HandleAddRender = this.props.isAdmin() ? this.handleAdd : false;
	}
	return (
		<div className="docs-page-inner-cont">
			{props.headBar !== false && (
				<PageHeadBar
					typeId={"documentTypeId"}
					search={handleSearch}
					curSite={props.curSite}
					siteSelect={SiteSelectRender}
					title={props.title}
					menuToggler={props.menuToggler}
					favState={props.savedState}
					dispCats={dispCats}
					selectCategory={selectCategory}
					resetCatsFilter={resetCatsFilter}
					catsType={"documents"}
					buttons={[
						{
							title: t("Commons.FAVORITES"),
							classes:
								"btn-favs-docs btn" +
								(props.savedState
									? " btn-primary"
									: ""),
							onClick: () => {
								props.setSaved(!props.savedState);
							}
						},
					]}
					isMenuOpen={props.isMenuOpen}
					setIsMenuOpen={props.setIsMenuOpen}
					handleAdd={handleAdd}
					addTitle={t("Docs.ADD_DOC")}
					filters={true}
					display={props.display}
					setDisplay={props.setDisplay}
				/>
			)}
			<div className="d-flex flex-column h-100 mt-2 overflow-hidden">
				{curDoc && (
					<DocPreview
						typeId={"documentTypeId"}
						id="doc-preview"
						className="doc-preview"
						onClose={handleDocClick}
						documentId={curDoc.DocumentId}
						handleModify={
							props?.isAdmin ? handleModify : false
						}
					/>
				)}
				{props.display !== "table" && (
					<HighlightedCategories
						typeId="documents"
						onItemClick={(item) => {
							selectCategory(item);
						}}
						selected={dispCats}
					/>
				)}
				{!editDoc && (
					<DocsList
						isAdmin={props.isAdmin}
						docs={searchResults || props.docs}
						handleDocClick={handleDocClick}
						dispCats={dispCats}
						display={props.display}
						searching={search?.length}
					/>
				)}
			</div>
			{editDoc && (
				<DocEditDrawer
					typeId={props.typeId}
					document={editDoc}
					isOpen={true}
					onClose={handleCloseEdit}
					initQueries={[
						{
							queryKey: [
								"DocumentEdit",
								"FetchForEdit",
								Date.now(),
							],
							queryFn: () =>
								editDoc.fetchForEdit(
									"data:application/pdf;base64,"
								),
						},
					]}
				/>
			)}
		</div>
	);

};

export default DocumentsPage;

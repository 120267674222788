import { useQuery } from "react-query";
import documents_treat from "includes/documents_treat";
import genRequest from "includes/request";

function useCompanyLogo(companyId, theme) {
	const typeId = theme === "dark" ? 17 : 10;

	return useQuery(
		["company-logo", companyId, typeId],
		async () => {
			const resp = await genRequest(
				`Documents/DocumentOfCompanyAndType/${companyId}/${typeId}`
			);
			return documents_treat(resp, "LastChanged", 1)[0];
		},
		{
			staleTime: 1000 * 60 * 60, // 1 heure
			retry: 1,
			enabled: !!companyId,
		}
	);
}

export default useCompanyLogo;

import noAccent from "includes/no_accents";
import React from "react";
import { useQuery } from "react-query";
import TagLabel from "../Label/TagLabel";
import { useFilterContext } from "providers/FilterProvider";
import { useMediaQuery } from "react-responsive";

export default function TagsList(props) {
	const { categoryIdsArray, setCategoryIdsArray } = useFilterContext();
	const { items, onItemClick, title, color, selected, className, unstyled, reset } = props;
	const { sortBy, revSort } = props
	const isMobile = useMediaQuery({ query: "(max-width: 700px)" });

	if (!items?.length)
		return null;

	function handleClick(item) {
		if (onItemClick) {
			if (isMobile) {
				if (categoryIdsArray.indexOf(item.id) === -1)
					setCategoryIdsArray(prev => [...prev, item.id]);
				else
					setCategoryIdsArray(prev => prev.filter(a => a !== item.id));
			}
			onItemClick(item);
		}
	}

	const getList = () => {
		const sorted = [...items];
		if (sortBy) {
			sorted.sort((a, b) => {
				const aVal = a[sortBy];
				const bVal = b[sortBy];
				if (typeof aVal === "number" && typeof bVal === "number") {
					return revSort ? bVal - aVal : aVal - bVal;
				}
				const aStr = noAccent(a[sortBy]).toLocaleLowerCase();
				const bStr = noAccent(b[sortBy]).toLocaleLowerCase();
				if (aStr < bStr) return revSort ? 1 : -1;
				if (aStr > bStr) return revSort ? -1 : 1;
				return 0;
			});
		}
		return sorted;
	};

	const list = getList();

	const selectedItem = isMobile ? categoryIdsArray : selected;

	return (
		<div
			className={"TagsList d-flex flex-wrap gap-1" + (className ? " " + className : "")}
		>
			{title &&
				<div className="w-100">{title}</div>}
			{
				onItemClick &&
				<TagLabel
					key={0}
					text="Tout"
					title="Tout afficher"
					selected={!selected?.length}
					color={(!selected?.length || selected.length === list.length) ? color : "#F2F2F4"}
					onClick={reset}
					unstyled={unstyled}
				/>
			}
			{list.map((item, index) => {
				const isSelected = selectedItem?.length
					? selectedItem.includes(item.id)
					: true;
				const itemColor =
					selectedItem?.length && !isSelected ? "#F2F2F4" : color || item.color;
				return (
					<TagLabel
						unstyled={unstyled}
						key={`${item.id}-${index}`}
						{...item}
						noClick={!onItemClick}
						selected={isSelected}
						color={itemColor}
						onClick={() => handleClick(item)}
					/>
				);
			})}
		</div>
	);
}

export function QueryTagsList(props) {
	const { isLoading, data, error } = useQuery(
		props.queryKey,
		props.queryFn,
		props.queryOptions || { retry: 0 }
	);

	if (isLoading)
		return (
			<div className="loading">
				<div className="w-100"></div>
			</div>
		);
	if (error)
		return (false);

	let items = data;

	if (data?.length) {
		if (props.treat)
			items = data.map(a => props.treat(a)).filter(a => a);
		else
			items = data.map((a) => {
				let id = a.CategoryId;
				if (a.hasOwnProperty("GroupId"))
					id = a.GroupId; {
				}
				return { id, title: a.Description, text: a.Name, color: a.DefaultHexaColor }
			});
		items = items.sort((a, b) => (noAccent(a.text.toLowerCase()) < (noAccent(b.text.toLowerCase())) ? -1 : 1));
	}
	return (<TagsList {...props} items={items} />);
}

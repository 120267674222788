import React from "react";
import "leaflet/dist/leaflet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhoneAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faEdit } from "@fortawesome/free-regular-svg-icons";
import "./SitePreview.css";
import SiteLogo from "../SiteLogo/SiteLogo";
import Map from "../Map/Map";
import { SiteMapPopup } from "../Map/Map";
import { t } from "i18next";
import { AsyncUsersList } from "components/Users/UsersList/UsersList";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";
import InfosFetcher from "components/InfosFetcher/InfosFetcher";
import { useQueryClient } from "react-query";
import useSelect from "hooks/useSelect";

export default function SitePreview(props) {
	const { site } = props;
	const { isAdmin } = useAccount();
	const { confirmDelete } = useSelect();
	const QCL = useQueryClient();

	let loc = props.site.Localisation;
	let point;
	if (loc) {
		loc = loc.split(",");
		if (loc.length < 2)
			loc = false;
		else {
			point = {
				lat: parseFloat(loc[0]),
				lng: parseFloat(loc[1])
			};
		}
	}

	function handleDelete() {
		if (!site?.SiteId)
			return (false);
		let title = t("Sites.ASKDELETE", {
			name: site.Name,
			count: 1
		});
		confirmDelete(
			delSite,
			null,
			title
		);
	}

	function delSite() {
		if (!site.SiteId)
			return (false)
		let req = genRequest(
			`Sites/SiteId=${site.SiteId}AndDeleteAnimation=falseAndDeleteEmployes=falseAndDeletePost=falseAndDeleteDoc=false`,
			null,
			"delete",
			{
				toast: true
			}
		).then(() => {
			QCL.resetQueries(["Sites"]);
			if (props.onDelete)
				props.onDelete();
		});
		return (req);
	}

	return (
		<div className="SitePreview">
			<div className="d-flex justify-content-between row align-items-start mb-3">
				<div className="site-identity col-12 col-sm-8 order-1 order-sm-0">
					<SiteLogo className="me-3" imgAlt={[site.Name, site.Town, site.Country].join("-")} site={site} siteId={site.SiteId} companyId={site.CompanyId} />
					<div className="d-flex flex-wrap justify-content-between w-100 overflow-hidden">
						<div className="site-info d-flex flex-column">
							<div className="title font-bold mb-2">{site.Name}</div>
							<div className="location mb-2">
								<div>{site.Adress}</div>
								{[site.PostalCode, site.Town].filter(a => a).join(" - ")}
								{site.Country && <div>{site.Country}</div>}
							</div>
							<div className="contacts">
								{site.Mail?.length && <div className="site-mail"><a href={"mailto:" + site.Mail}><FontAwesomeIcon className="site-contact-icon me-2" icon={faEnvelope} />{site.Mail}</a></div>}
								<div className="d-flex align-items-center gap-2">
									{(site.Phone !== "") && <div className="site-whatsapp"><a href={"whatsapp:" + site.Phone}><FontAwesomeIcon className="site-contact-icon" icon={faWhatsapp} /></a></div>}
									{(site.Phone !== "") && <div className="site-phone"><a href={"tel:" + site.Phone}><FontAwesomeIcon className="site-contact-icon me-2" icon={faPhoneAlt} />{site.Phone}</a></div>}
								</div>
							</div>
						</div>
					</div>
				</div>
				{
					isAdmin() &&
					<div className="d-flex col-12 col-sm-4 order-0 order-sm-1 mb-2 mb-sm-0 justify-content-end gap-2">
						<div className="ms-auto btn btn-blue w-auto" onClick={props.handleModify}>
							{t("Commons.MODIFY")}
							<FontAwesomeIcon className="ms-2" icon={faEdit} />
						</div>
						<div className="btn btn-red w-auto" onClick={handleDelete}>
							{t("Commons.DELETE")}
							<FontAwesomeIcon className="ms-2" icon={faTrashAlt} />
						</div>
					</div>
				}
			</div>
			<div className="mt-auto">
				<InfosFetcher
					className="nbr-member-txt"
					queryKey={["Employes", "SortedMinimalContactEmployesOfSite", site.SiteId]}
					queryFn={() => genRequest("Employes/SortedMinimalContactEmployesOfSite?SiteId=" + site.SiteId)}
					treat={(infos) => {
						return (infos.Items.length ? infos.Items.length + ` ${t("Sites.MEMBERS")}` : false);
					}}
				/>
				<AsyncUsersList
					queryFn={() => genRequest("Employes/SortedMinimalContactEmployesOfSite?SiteId=" + site?.SiteId)}
					queryKey={["Employes", "SortedMinimalContactEmployesOfSite", site?.SiteId]}
					max={10}
					width={40}
					className={"dark-bg"}
				/>
			</div>
			{
				loc && point &&
				<div className="mt-2">
					<Map points={[point]} updateOnRefocus={false} popUp={<SiteMapPopup site={site} />} />
				</div>
			}
		</div>
	);
}

import axios from "axios";
import Storage from "classes/Storage/Storage";
import { API_URL } from "./paths";
import formatDate from "./formatDate";
import * as Sentry from "@sentry/react";
import promiseToast from "components/Toasts/PromiseToast";

export default function genRequest(url, data, method, props) {
	const storage = new Storage(null, null, localStorage);
	const localStor = new Storage();
	const userToken = storage.getItem("token");
	let token = userToken?.access_token;

	if (props?.token) token = props.token;
	let settings = {
		url: props && props.forceUrl ? url : API_URL + url,
		headers: {
			"Content-Type": props?.contentType
				? props?.contentType
				: "application/json",
			Authorization: "bearer " + token,
		},
		data: data,
		method: method ? method : "get",
		onUploadProgress: props?.onUploadProgress,
		onDownloadProgress: props?.onDownloadProgress,
		timeout: props?.timeout,
	};

	if (method === "get" && data) {
		settings.params = data;
		settings.data = undefined;
	}

	if (!method && data) settings.method = "post";

	let req = axios(settings).then(
		(resp) => {
			return resp.data;
		},
		(e) => {
			if (e.response?.status === 401) {
				storage.removeItem("token");
				const pathname = window.location.pathname.split("/")[1];
				const accounts = localStor.getItem("Accounts");
				const accountsModified = accounts.map((a) => {
					if (a.EmployesId === parseFloat(pathname)) {
						a.Token = null;
						a.Token_Regeneration = true;
						return a;
					} else {
						return a;
					}
				});
				localStor.setItem("Accounts", accountsModified);
				if (!window.location.pathname.startsWith("/login")) {
					window.location.href = "/login";
				}
			}
			if (props?.reportOnFail) {
				let err_req = sendReport(e, {
					url,
					data,
					method,
					props,
				});
				throw err_req;
			}
			throw e;
		}
	);
	if (props?.toast)
		promiseToast(
			req,
			typeof props.toast !== "boolean" ? props.toast : null
		);
	return req;
}

export function sendReport(error, datas) {
	if (!datas) datas = {};
	let status = error?.response?.status;
	let date = formatDate();
	if (status === 404) return false;

	error.name = `${
		error?.name
	} - ${datas.method?.toUpperCase()} - ${status} - ${datas.url.replace(
		API_URL,
		""
	)}`;

	let vals = {
		Title: `${datas.method?.toUpperCase()} Error - ${status} - ${datas.url.replace(
			API_URL,
			""
		)}`,
		Date: date,
		...datas,
		response: error.response?.data,
	};

	Sentry.withScope((scope) => {
		let user = scope.getUser();
		scope.addAttachment({
			filename: date + "-report.json",
			data: { ...vals, user },
			contentType: "application/json",
		});
		Sentry.captureException(error);
	});
}

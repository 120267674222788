import ErrorsCont from "components/ErrorsCont/ErrorsCont";
import documents_treat, { humanFileSize } from "includes/documents_treat";
import formatDate from "includes/formatDate";
import genRequest from "includes/request";
import { t } from "i18next";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import "./ImageSender.css";
import Button from "components/Button/Button";

function ImageSender(props) {
	const [imgUrl, setImgUrl] = useState(props.imgUrl ? props.imgUrl : false);
	const [curImage, setCurImage] = useState(false);
	const [error, setError] = useState(false);
	const [progressEvent, setProgressEvent] = useState(false);
	const [queryKeys, setQueryKeys] = useState([]);
	const [queryUrl, setQueryUrl] = useState([]);
	const queryClient = useQueryClient();
	const default_max_size = 2 * Math.pow(1024, 2);

	React.useEffect(
		(old_props) => {
			if (old_props?.imgUrl !== props?.imgUrl) {
				setImgUrl(props.imgUrl);
				setCurImage(false);
			}

			if (
				(props.siteId || props.companyId) &&
				(old_props?.companyId !== props?.companyId ||
					old_props?.siteId !== props?.siteId)
			) {
				let url =
					"Documents/DocumentOfCompanyAndType/" +
					props.companyId +
					"/" +
					props.typeId;
				if (props.siteId)
					url =
						"Documents/AllDocumentsOfSiteTyped/" +
						props.siteId +
						"/" +
						props.typeId;
				setQueryUrl(url);
				setQueryKeys(url.split("/"));
			}
		},
		[setImgUrl, setCurImage, props]
	);

	function retrieveImage() {
		let type = "company";
		if (
			queryUrl ===
			"Documents/AllDocumentsOfSiteTyped/" +
			props.siteId +
			"/" +
			props.typeId
		)
			type = "site";
		let req = genRequest(queryUrl, null, "get", {
			onDownloadProgress: (e) => {
				setProgressEvent({
					title: t("Images.IMAGE_LOAD"),
					event: e,
				});
			},
		}).then((images) => {
			setProgressEvent(false);
			if (images.length)
				return {
					file: documents_treat(images, "LastChanged", 1)[0],
					type: type,
				};
			return {
				file: false,
				type: type,
			};
		});
		return req;
	}

	const getExt = (name) => name.split(".").pop();

	function handleChange(event) {
		let files = event.target.files;
		let reader = new FileReader();
		let file = files[0];

		setError(false);
		if (file) {
			let max = props.maxSize ? props.maxSize : default_max_size;
			if (max && file.size > max) {
				setError(
					t("Images.IMAGE_SIZE_ERROR") +
					" (" +
					humanFileSize(file.size) +
					") " +
					t("Images.IMAGE_SIZE_ERROR_2") +
					" " +
					humanFileSize(max)
				);
				event.target.value = "";
				return false;
			}
			reader.readAsDataURL(file);
			reader.onloadend = (e) => {
				let loaded_file = file;
				loaded_file.data = e.target.result;
				loaded_file.ext = getExt(file.name);
				if (props.sendImage !== false) {
					sendImage(loaded_file).then((resp) => {
						if (!props.isDefault)
							queryClient.resetQueries(queryKeys);
						setProgressEvent(false);
						if (props.onChange) props.onChange(resp);
					});
				} else if (props.onChange) props.onChange(file);
				event.target.value = "";
			};
		}
	}

	const deleteImage = (docId) =>
		genRequest(
			"Documents/DocumentId=" + docId + "AnddeleteExpenses=true",
			null,
			"delete"
		).then(
			() => {
				setCurImage(false);
				return Promise.resolve();
			},
			() => {
				setCurImage(false);
				return Promise.resolve();
			}
		);

	function sendImage(file) {
		let prom;

		if (props.sendImage) {
			prom = props
				.sendImage({
					companyId: props.companyId,
					typeId: props.typeId,
					typeName: props.typeName,
					file: file,
					setProgressEvent: setProgressEvent,
				})
				.then((resp_img_url) => {

					setImgUrl(resp_img_url);
				});
			toast.promise(prom, {
				pending: t("Toasts.PROM_PENDING"),
				success: t("Toasts.PROM_SUCCESS"),
				error: t("Toasts.PROM_ERROR"),
				autoClose: 7000,
			});
			return prom;
		}

		prom = new Promise((resolve, reject) => {
			if (curImage && curImage.DocumentId) {
				let docId = curImage.DocumentId;
				setCurImage({
					...curImage,
					DocumentId: null,
				});
				deleteImage(docId);
			}
			let send_prom = genRequest(
				"Documents/DocumentFileFromString",
				{
					Name:
						file.name.split(".")[0] +
						"_" +
						formatDate() +
						"." +
						file.ext,
					FileDocString: file.data.split(",")[1],
					DocTypeId: props.typeId,
					CompanyId: props.companyId,
					SiteId: props.siteId,
				},
				"post",
				{
					onUploadProgress: (e) => {
						setProgressEvent({
							title: t("Images.IMAGE_SEND"),
							event: e,
						});
					},
				}
			)
				.then((docId) => {
					return linkImage(docId);
				}, reject)
				.then((resp) => {
					resolve(resp);
				}, reject);
			return send_prom;
		});

		toast.promise(prom, {
			pending: t("Toasts.PROM_PENDING"),
			success: t("Toasts.PROM_SUCCESS"),
			error: t("Toasts.PROM_ERROR"),
			autoClose: 7000,
		});

		return prom;
	}

	const linkImage = (docId) => {
		let prom;
		if (props.siteId) {
			prom = genRequest(
				"LtDocumentSites",
				{
					DocumentId: docId,
					SiteId: props.siteId,
				},
				"post"
			);
		} else
			prom = genRequest(
				"LtDocumentCompanies",
				{
					DocumentId: docId,
					CompanyId: props.companyId,
				},
				"post"
			);
		return prom;
	};

	function handleDelete() {
		setProgressEvent(false);
		if (props.delete && !props.deleteOrigin) {
			setCurImage(false);
			setImgUrl(false);
			if (props.nullable === false)
				queryClient.invalidateQueries(queryKeys);

			if (props.onChange) props.onChange(false);
		} else if (curImage.DocumentId) {
			deleteImage(curImage.DocumentId).then(() => {
				if (!props.isDefault) queryClient.invalidateQueries(queryKeys);
				if (props.onChange) props.onChange();
			});
		}
	}

	const req = useQuery(queryKeys, () => retrieveImage(), {
		enabled:
			!props?.isDefault && props?.companyId > 0 && queryUrl?.length > 0,
		onSuccess: ({ file, type }) => {
			setCurImage(file);
			setImgUrl(file?.Url_Origin);
			if (props.onFetched)
				props.onFetched({
					...file,
					type: type,
					CompanyId: props.companyId,
					SiteId: props.siteId,
				});
		},
		retry: (count) => {
			if (props.siteId && count === 0) {
				let url =
					"Documents/DocumentOfCompanyAndType/" +
					props.companyId +
					"/" +
					props.typeId;
				if (queryUrl !== url) {
					setQueryUrl(
						"Documents/DocumentOfCompanyAndType/" +
						props.companyId +
						"/" +
						props.typeId
					);
					setQueryKeys([
						"Documents",
						"DocumentOfCompanyAndType",
						props.companyId,
						props.typeId,
					]);
				}
				return true;
			}
			return false;
		},
		refetchOnWindowFocus: false,
	});

	const { isLoading, isFetching } = req;

	return (
		<div
			className={
				"ImageSender" +
				(props.className ? " " + props.className : "") +
				(!isLoading && imgUrl ? " hasImage" : "")
			}
		>
			<div className="d-flex align-items-center justify-content-between mb-1">
				<div className="sender-title mb-0 title-form mt-2">{props.title}</div>
				{props.delete !== false && (
					<div>
						<Button
							className="delete-image"
							title={t("Images.RM_IMAGE")}
							onClick={handleDelete}
						>
							<div className="text-start">
								{t("Images.RM_IMAGE")}
							</div>
							<FontAwesomeIcon
								className="ms-2"
								icon={faTrashAlt}
							/>
						</Button>
					</div>
				)}
			</div>
			{props.description && (
				<div className="sender-desc">{props.description}</div>
			)}
			<ErrorsCont className="mb-2" errors={error} setErrors={setError} />
			<div className="w-100">
				<div className={"send-image-cont"}>
					{isLoading || isFetching || progressEvent ? (
						<Loading
							isLoading={isLoading}
							isFetching={isFetching}
							progressEvent={progressEvent}
							title={t("Images.IMAGE_LOAD")}
						/>
					) : (
						<>
							<input
								type="file"
								className="image-input"
								accept=".png, .jpg, .jpeg "
								title={t("Images.IMAGE_OVERLAY")}
								onChange={handleChange}
							/>
							<div className="image-overlay">
								{t("Images.MODIFY_IMAGE_OVERLAY")}
							</div>
							<div
								className="image-preview"
								style={{
									backgroundImage: "url(" + imgUrl + ")",
								}}
							/>
						</>
					)}
					{!imgUrl && (
						<div className="placeholder">
							{t("Images.IMAGE_OVERLAY")}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

function Loading(props) {
	let title = props.title ? props.title : t("Commons.LOADING");

	if (!(props.isFetching || props.isLoading || props.progressEvent))
		return false;
	if (props.progressEvent.event) {
		if (props.progressEvent.title) title = props.progressEvent.title;
		title +=
			" - " +
			Math.round(
				(props.progressEvent.event.loaded /
					props.progressEvent.event.total) *
				100
			) +
			"%";
	}
	return <div className="loading-cont">{title}</div>;
}

export default ImageSender;

import { Icon } from "@iconify/react"
import Button from "components/Button/Button"
import { t } from "i18next"
import { confirmAlert } from "react-confirm-alert"
import "./pwaStyle.styles.css"

// This Prompt is only for devices which have PWA support and cannot install automatically
export const manualPrompt = async ({ doNotShowAgain, alreadyInstalled, isIOS, isAndroid }) => {
    confirmAlert({
        overlayClassName: "pwa_overlay",
        customUI: ({ onClose: close }) => {
            return <>
                <div className="pwaModalContainer">
                    <div className="flex flex-row justify-between gap-6">
                        <div className="flex items-start -mr-6">
                            <img style={{ width: "118px" }} src="/assets/logos/logo.png" />
                        </div>
                        <div className="flex flex-col gap-2">
                            <h4 className="text-[18px] font-bold intro_pwa">
                                {t('PWAMsg.Title')}
                            </h4>
                            <p className="text-[14px] intro_pwa">
                                {t('PWAMsg.Message')}
                            </p>
                            <div className="flex justify-start pwa_instructions">
                                {/* Prompt for ios Devices */}
                                {isIOS && !isAndroid ?
                                    <div className="flex flex-col gap-2 mt-2 text-[14px]">
                                        <div className="inline-flex items-center gap-2 items-pwa">
                                            1.  {t('PWAMsg.ClickThe')} <Icon icon="material-symbols:ios-share" />
                                        </div>
                                        <div className="items-pwa">2. {t('PWAMsg.ScrollDown')}</div>
                                        <div className="inline-flex items-center gap-2 items-pwa">
                                            3.  {t('PWAMsg.AddToHomeScreen')} <Icon icon="icon-park-outline:add" />
                                        </div>
                                    </div>
                                    :
                                    <div className="flex flex-col gap-2 mt-2 text-[14px]">
                                        <div className="inline-flex items-center gap-2 items-pwa">
                                            1. {t('PWAMsg.ClickThe')} <Icon icon="zondicons:dots-horizontal-triple" />
                                        </div>
                                        <div className="items-pwa">2.  {t('PWAMsg.ScrollDown')}</div>
                                        <div className="inline-flex items-center gap-2 items-pwa">
                                            3. <Icon icon="ic:baseline-add-to-home-screen" /> {t('PWAMsg.AddToHomeScreen')}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row gap-2">
                        <Button
                            className="gray"
                            onClick={() => close()}
                        >
                            {t('PWAMsg.AlreadyInstalled')}
                            <Icon
                                icon="fluent:checkbox-checked-20-filled"
                                className="ml-2 text-green-500"
                                width={20}
                            />
                        </Button>
                        <Button
                            className="gray"
                            onClick={() => doNotShowAgain(close)}
                        >
                            {t('PWAMsg.DontShowItAgin')}
                            <Icon
                                icon="teenyicons:message-no-access-solid"
                                className="ml-2 text-blue-500"
                                width={20}
                            />
                        </Button>
                    </div>
                </div>
            </>
        },
    })
}
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";
import "components/User/Card/UserCard.css";
import "./contacts.css";
import genRequest from "includes/request";
import { t, use } from "i18next";
import PreviewDrawer from "components/PreviewDrawer/PreviewDrawer";
import UserPreviewDrawer from "Pages/Contacts/components/UserPreviewDrawer/UserPreviewDrawer";
import PageHeadBar from "components/HeadBar/PageHeadBar";
import PageLoad from "components/PageLoad/PageLoad";
import useAccount from "classes/Accounts/hooks/useAccount";
import useDisplay from "hooks/useDisplay";
import UserEditDrawer from "Pages/Contacts/components/UserEditDrawer/UserEditDrawer";
import EmployeObject from "classes/Employes/EmployeObject";
import Button from "components/Button/Button";
import ContactsBody from "./components/ContactsBody";
import UsersManager from "./components/UsersManager/UsersManager";
import noAccent from "includes/no_accents";
import useEditing from "components/EditDrawer/useEditing";
import { UsersProvider } from "./providers/UsersProvider";
import { usePostContext } from "providers/PostProvider";
import { useFilterContext } from "providers/FilterProvider";
import { useMediaQuery } from "react-responsive";

export default function ContactsPage(props) {
	const { account, isSup, isAdmin } = useAccount();
	const [filters, setFilters] = useState({});
	const [CurSite, setCurSite] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalUsers, setTotalUsers] = useState(0);
	const [allContacts, setAllContacts] = useState([]);
	const [CurUser, setCurUser] = useState(false);
	const [ShowSaved, setShowSaved] = useState(false);
	const [DispState, setDipState] = useState(false);
	const [DispGroups, setDispGroups] = useState([]);
	const { display, setDisplay } = useDisplay();
	const last_display = React.useRef();
	const [EditObj, setEditObj] = useState(false);
	const [Search, setSearch] = useState(false);
	const [isManagerOpen, setIsManagerOpen] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const { setEditing } = useEditing();
	const QCL = useQueryClient();
	const { postToCreate, setPostToCreate } = usePostContext();
	const { setCategoryIdsArray, setCategoriesSelected, categoryIdsArray } = useFilterContext();
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

	const { employeId } = Object.fromEntries(
		new URLSearchParams(window.location.search)
	);

	useEffect(() => {
		if (props.platform !== last_display.current) {
			if (props.platform === "admin" && props.display !== "table")
				setDisplay("table");
			else if (props.platform !== "admin" && props.display !== "tiles")
				setDisplay("tiles");
		}
		last_display.current = props.platform;
	}, []);

	useEffect(() => {
		if (postToCreate) {
			// handleAddingNewPost(postToCreate);
			postToCreate.fetchForEdit().then(() => {
				setEditObj(postToCreate);
			});
		}
	}, [postToCreate]);


	useEffect(() => {
		const getOneEmployeFormParamUrl = async () => {
			const employe = await getOneEmploye(employeId);
			setCurUser(employe);
		};
		if (employeId !== void 0) {
			getOneEmployeFormParamUrl();
		}
	}, [employeId]);

	useEffect(() => {
		const updateFilters = () => {
			let updatedFilters = {};

			if (DispState === "actives") {
				updatedFilters = { ...updatedFilters, GetActiveUsers: true };
			}

			if (DispState === "inactives") {
				updatedFilters = { ...updatedFilters, GetInactiveUser: true };
			}

			if (ShowSaved) {
				updatedFilters = { ...updatedFilters, GetFavoritesUsers: true };
			}

			if (Search && Search !== "") {
				updatedFilters = { ...updatedFilters, SearchedTerms: Search };
			}

			if (DispGroups.length > 0 && DispGroups[0] !== void 0) {
				updatedFilters = { ...updatedFilters, SearchedCategories: DispGroups };
			}
			if (CurSite) {
				updatedFilters = { ...updatedFilters, SiteId: CurSite.value };
			}

			return updatedFilters;
		};

		const updatedFilters = updateFilters();
		setFilters(updatedFilters);
		refetch();

	}, [Search, DispState, DispGroups, ShowSaved, CurSite, categoryIdsArray]);

	const treatUsers = async (users) => {
		const sitesOfCompany = await genRequest("Sites/SitesOfCompany/" + account.CompanyId);
		const groups = await genRequest("Groups/OfCompany/" + account.CompanyId);
		const usersWith = users.map((x) => {
			const selected = x.GroupsOfUser.map(
				(y) => {
					const group = groups.find(
						(z) => z.GroupId === y
					);
					if (group) {
						return {
							id: group.GroupId,
							title: group.Description,
							text: group.Name,
							color: group.DefaultHexaColor,
						};
					}

				}
			);
			const site = sitesOfCompany.find(
				(y) => y.SiteId === x.SiteId
			);
			// const savedUser = savedUsersData.find(
			// 	(y) => y.EmployesId === x.EmployesId
			// );
			let dataToReturn = {
				...x,
			}
			if (selected) {
				dataToReturn.groups = selected;
			}
			if (site) {
				dataToReturn.siteInfo = site;
			}

			// dataToReturn.saved = savedUser ? true : false;

			return dataToReturn;
		});

		return usersWith;
	};


	const fetchEmployes = async (query) => {
		const { queryKey } = query;

		// const sitesOfCompany = await genRequest("Sites/SitesOfCompany/" + account.CompanyId);
		// const groups = await genRequest("Groups/OfCompany/" + account.CompanyId);
		// const savedUsersData = await fetchSavedUsers();
		let prom = new Promise((resolve, reject) => {
			let req = fetchEmployesOfCompany;
			if (CurSite?.value) {
				setCurrentPage(1);
				req = fetchEmployesOfSite;
			}
			if (queryKey[1] === "FilteredEmployes") {
				req = () => genRequest("Employes/FilteredSortedContactEmployesWithGroup", {
					CompanyId: account.CompanyId,
					pageNumber: currentPage,
					...filters
				}, "post");
			}

			req().then(async (resp) => {
				setTotalUsers(resp.TotalCount);
				const usersWith = await treatUsers(resp.Items);

				let usersTotal = usersWith;
				if (allContacts.length > 0 && !CurSite?.value && currentPage > 1) {
					usersTotal = allContacts.concat(usersWith);
				}
				if (currentPage === resp.TotalPages) {
					setHasMore(false);
				}
				setAllContacts(usersTotal);

				resolve(usersTotal);

			}, reject);
		});
		return prom;
	};

	const fetchForTable = async (page, pageSize) => {
		let req = fetchEmployesOfCompany;
		if (CurSite?.value) req = fetchEmployesOfSite;
		if (Object.keys(filters).length > 0) {
			req = () => genRequest("Employes/FilteredSortedContactEmployesWithGroup", {
				...filters,
				CompanyId: account.CompanyId,
				PageNumber: page,
				PageSize: pageSize
			}, "post");
		}
		return req(page, pageSize);
	};

	// const fetchMinimalContactEmployesFromCompany = async () =>
	// 	genRequest(
	// 		`Employes/MinimalContactEmployesOfCompany/${account.CompanyId}`
	// 	);

	///Employes/SortedContactEmployesWithGroupOfCompany?CompanyId={CompanyId}&PageNumber={PageNumber}&PageSize={PageSize}

	const fetchEmployesOfCompany = (page, pageSize) => {
		return genRequest(`Employes/SortedContactEmployesWithGroupOfCompany?CompanyId=${account.CompanyId}&PageNumber=${page ? page : currentPage}&PageSize=${pageSize ? pageSize : 25}`);
	}

	const fetchEmployesOfSite = (page, pageSize) =>
		genRequest(`Employes/SortedContactEmployesWithGroupOfSites?SiteId=${CurSite.value}&PageNumber=${page ? page : currentPage}&PageSize=${pageSize ? pageSize : 25}`);

	// const fetchSavedUsers = () =>
	// 	genRequest(
	// 		"Lt_Employe_Saved_Employe/OfEmployeId/" + account.EmployesId
	// 	);

	const getFetchRequest = () => {
		let queryKey = ["Employes", "EmployeOfCompany", account.CompanyId, currentPage];

		if (CurSite?.value)
			queryKey = ["Employes", "EmployeOfSite", CurSite.value, currentPage];
		if (Object.keys(filters).length > 0) {
			queryKey = ["Employes", "FilteredEmployes", JSON.stringify(filters)];
		}
		return {
			queryFn: fetchEmployes,
			queryKey,
		};
	};

	// useEffect(() => {
	// 	if (DispGroups[0] === void 0) {
	// 		// setDispGroups([]);
	// 	}
	// }, [DispGroups]);

	const getOneEmploye = async (id) => {
		let resp = await genRequest("Employes/FullContactEmploye/" + id);

		return resp.employe;
	};

	const stateFilter = (item) => {
		if (!DispState) return item;
		if (
			DispState === "actives" &&
			(item.FirstConnexion || item.LastConnexion)
		) return item;

		if (
			DispState === "inactives" &&
			!(item.FirstConnexion || item.LastConnexion)
		) return item;
		return false;
	};

	const selectGroup = (group) => {
		let groups = [...DispGroups];
		let group_id = group.id;
		let check = DispGroups.indexOf(group_id);

		if (check === -1) groups.push(group_id);
		else
			groups = groups
				.map((a) => {
					if (a === group_id) return false;
					return a;
				})
				.filter((a) => a);
		// if (groups[0] !== void 0) {
		setDispGroups(groups);
		setCategoryIdsArray(groups);
		// }
	};

	const handleClick = async (user, userId) => {
		let obj = new EmployeObject(user);
		if (user.EmployesId) {
			let resp = await genRequest("Employes/FullContactEmploye/" + user.EmployesId);
			obj.workFunctionId(resp.WFU.WorkFunctionId);
			obj.workFamilyId(resp.WFY.WorkFamillyId);
			obj.login(resp.employe.Login);
			setCurUser(obj.values());
		} else {
			setCurUser(false);
		}
	};

	const handleAdd = () => {
		let obj = new EmployeObject({
			CompanyId: account.CompanyId,
			SiteId: isSup("SuperAdmin") >= 0 ? false : account.SiteId,
		});
		obj.fetchForEdit().then(() => {
			setEditObj(obj);
		});
		setEditing(true);
	};

	const handleModify = (user) => {
		let obj = new EmployeObject(user);
		obj.fetchForEdit().then(() => {
			obj.groupsToLink(obj.linkedGroups());
			setEditObj(obj);
		});
		setEditing(true);
	};

	const closeEdit = (isDone) => {
		setCurUser(EditObj.employesId() ? EditObj.values() : false);
		setEditObj(false);
		setEditing(false);
		setPostToCreate(null);
		if (isDone) QCL.resetQueries(["Employes"]);
	};

	const query = getFetchRequest();
	const { isLoading, data, refetch, isRefetching, isFetching } = useQuery(query.queryKey, query.queryFn);

	// if (isLoading) return <PageLoad title={t("Contacts.LOADING")} />;
	// 
	// filterList(data);
	const users = data;

	const renderContactbody = () => {
		if (isLoading && allContacts.length === 0) {
			return <PageLoad title={t("Contacts.LOADING")} />;
		} else if (!isLoading && allContacts.length > 0) {
			return <ContactsBody
				users={users}
				search={Search}
				curUser={CurUser}
				filters={filters}
				handleClick={handleClick}
				currentPage={currentPage}
				getNextUsers={setCurrentPage}
				totalUsers={totalUsers}
				display={display}
				dispGroups={DispGroups}
				request={fetchForTable}
				treatUsers={treatUsers}
				hasMore={hasMore}
			/>
		}
		else {
			return <div className="d-flex justify-content-center align-items-center h-100">
				<h4 className="p-3">{t("Contacts.NO_USERS")}</h4>
			</div>
		}
	}

	return (
		<div className="ContactsPage d-flex flex-column overflow-hidden">
			<UsersProvider>
				<PageHeadBar
					//Fake id to add the same behavior as the other pages
					typeId={"contactTypeId"}
					siteSelect={setCurSite}
					curSite={CurSite}
					searchValue={filters.SearchedTerms}
					search={(val) => setSearch(val)}
					title={props.title}
					favState={ShowSaved}
					hideFavSites={true}
					buttons={[
						{
							title: t("Commons.FAVORITES"),
							classes:
								"btn-favs-docs btn" +
								(ShowSaved ? " btn-primary" : ""),
							onClick: () => setShowSaved(!ShowSaved),
						},
						{
							title: t("Contact_Page.Contact_Manage_User"),
							classes: "btn" + (isManagerOpen ? " btn-primary" : ""),
							onClick: () => setIsManagerOpen(!isManagerOpen),
							enabled: isAdmin() && display === "table",
						},
					]}
					addTitle={t("Contacts.ADD_BTN")}
					handleAdd={handleAdd}
					isMenuOpen={props.isMenuOpen}
					setIsMenuOpen={props.setIsMenuOpen}
					categories={true}
					dispCats={DispGroups[0] !== void 0 && DispGroups}
					selectCategory={selectGroup}
					resetCatsFilter={() => setDispGroups([])}
					catsTreat={(a) => ({
						id: a.GroupId,
						title: a.Description,
						text: a.Name,
						color: a.DefaultHexaColor,
					})}
					catsQueryKey={["Groups", "OfCompany", account.CompanyId]}
					catsQueryFn={() =>
						genRequest("Groups/OfCompany/" + account.CompanyId)
					}
					display={display}
					setDisplay={setDisplay}
					filters={true}
					filterChilds={[
						{
							enabled: isAdmin(),
							title: t("Contacts.USERS_STATUS"),
							component: UsersStateFilter,
							props: {
								state: DispState,
								setState: setDipState,
							},
							filterCount: DispState !== false ? 1 : 0,
							resetFilter: () => setDipState(false),
						},
					]}
				/>
				{renderContactbody()}
				{CurUser && (
					<PreviewDrawer
						child={UserPreviewDrawer}
						fullScreen={true}
						onClose={() => handleClick(false)}
						isOpen={CurUser ? true : false}
						childProps={{
							user: CurUser,
							handleModify:
								isAdmin() && isSup(CurUser.Role) >= 0
									? handleModify
									: false,
						}}
					/>
				)}
				{EditObj && (
					<UserEditDrawer
						adderRights={props.rights}
						user={EditObj}
						isOpen={true}
						onClose={closeEdit}
					/>
				)}
				{!isLoading && isManagerOpen && (
					<UsersManager
						users={users}
						groups={DispGroups}
						site={isSup("SiteAdmin") > 0 ? CurSite : false}
						search={Search}
						showSaved={ShowSaved}
						userState={DispState}
						handleClose={() => setIsManagerOpen(false)}
					/>
				)}
			</UsersProvider>
		</div>
	);
}

const UsersStateFilter = (props) => {
	const { state, setState } = props;

	return (
		<div>
			<div className="d-flex gap-2">
				<Button
					className={"w-auto " + (!state ? "btn-primary" : "btn-bg")}
					onClick={() => setState(false)}
					text={t("Contacts.ALL_USERS")}
				/>
				<Button
					className={
						"w-auto " +
						(state === "actives" ? "btn-primary" : "btn-bg")
					}
					onClick={() => setState("actives")}
					text={t("Contacts.ACTIVE")}
				/>
				<Button
					className={
						"w-auto " +
						(state === "inactives" ? "btn-primary" : "btn-bg")
					}
					onClick={() => setState("inactives")}
					text={t("Contacts.INACTIVE")}
				/>
			</div>
		</div>
	);
};

import React, { useState } from "react";
import useAccount from "classes/Accounts/hooks/useAccount";
import { AsyncScrollTable } from "components/ScrollTable/ScrollTable";
import genRequest from "includes/request";
import { t } from "i18next";
import EmployeSelector from "components/Users/UsersSelector/UsersSelector";

export default function AlertTargetsStep(props) {
	const { account } = useAccount();
	const alert = props.object;
	const [selectedsUsers, setSelectedsUsers] = useState(alert.targets());
	const [isSelectOpen, setIsSelectOpen] = useState(true);

	function changeSelected(sels) {
		alert.targets(sels);
		setSelectedsUsers(sels);
	}

	return (
		<div
			className="inner-step-cont d-flex flex-column h-100 overflow-hidden"
			style={{ maxHeight: "80%" }}
		>
			<div className="d-flex justify-content-between align-items-center w-100 mb-2">
				<div>{t("Posts.SELECTED_TARGETS", { count: selectedsUsers.length })}</div>
				<div>
					{
						isSelectOpen ?
							<div className="btn btn-grey" onClick={() => setIsSelectOpen(false)}>{t("Commons.BACK")}</div>
							:
							<div className="btn btn-blue" onClick={() => setIsSelectOpen(true)}>{t("Commons.ADD")}</div>
					}
				</div>
			</div>
			<div
				className="d-flex h-100 flex-column"
			>
				{
					isSelectOpen ?
						<EmployeSelector setSelectedsUsers={changeSelected} selectedsUsers={selectedsUsers} />
						:
						<div className="h-100 overflow-hidden d-flex">
							<AsyncScrollTable
								queryKey={["Employes", "EmployeOfCompany", account.CompanyId, "Alerts"]}
								queryFn={() => genRequest("Employes/EmployeOfCompany/" + account.CompanyId)}
								itemId="EmployesId"
								items={selectedsUsers}
								setSelected={changeSelected}
								selected={selectedsUsers}
								className="light-bg h-100 d-flex flex-column overflow-hidden"
								mouseover={false}
								onItemClick={() => { }}
								cols={[
									{
										name: "LastName",
										title: t("User.LAST_NAME")
									},
									{
										name: "FirstName",
										title: t("User.FIRST_NAME")
									}
								]}
								filter={(a) => {
									a.isSelected = true;
									return (a);
								}}
								sortBy="LastName"
								revSort={false}
								checkbox={true}
								onlySelected={true}
							/>
						</div>
				}
			</div>
		</div>
	);
}

export function checkAlertStep(post) {
	if (!post.targets()?.length && !post.draft())
		return ([t("ErrorMsg.Target")]);
	return (true);
}

import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick;
import "./Calendar.css";
import preg_replace from "includes/preg_replace";
import formatDate from "includes/formatDate";
import { t } from "i18next";
import useUnread from "classes/Accounts/hooks/useUnread";
import { useContextMenu } from "react-contexify";
import useAccount from "classes/Accounts/hooks/useAccount";
import CalendarCtxMenu from "./CalendarCtxMenu";
import { useMediaQuery } from "react-responsive";

let LAST_CLICK = false;

export default function Calendar(props) {

	const { isAdmin } = useAccount();
	const calRef = React.createRef();
	const cal_cont_ref = React.createRef();
	const [isInitied, setIsInitied] = useState(false);
	const { isReaded } = useUnread();
	const dayCtxMenuId = "day-ctx-menu";
	const eventCtxMenuId = "event-ctx-menu";
	const { show } = useContextMenu();
	const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

	React.useEffect(() => {
		if (!isMobile) {
			if (cal_cont_ref && cal_cont_ref.current && !isInitied) {
				cal_cont_ref.current.querySelector(".fc-changeToGrid-button").classList.add("active");

				let date = calRef.current.getApi().getDate();
				let day = cal_cont_ref.current.querySelector(".fc-daygrid-day[data-date=\"" + formatDate(date, "Y-M-D") + "\"]");
				day.classList.add("active");
				setIsInitied(true);
			}
		}
	}, [cal_cont_ref, calRef, isInitied, setIsInitied]);

	const handleDateClick = (arg) => {
		// arg.jsEvent.preventDefault()
		// arg.jsEvent.stopPropagation()

		if (LAST_CLICK && LAST_CLICK.target === arg.jsEvent.target && arg.jsEvent.timeStamp - LAST_CLICK.timeStamp < 800) // double click
		{
			props.handleAdd({ DateStart: new Date(arg.date.setHours(8, 0, 0, 0)) });
			LAST_CLICK = arg.jsEvent;
			return;
		}

		LAST_CLICK = arg.jsEvent;

		let calendar = cal_cont_ref.current;
		if (!calendar)
			return (false);

		let active = calendar?.querySelector(".fc-daygrid-day.active");
		if (active)
			active.classList.remove("active");
		calendar.querySelector(".fc-daygrid-day[data-date=\"" + arg.dateStr + "\"]").classList.add("active");

		let events = getDayEvents(arg.dateStr);

		if (props.handleDateClick)
			props.handleDateClick(arg, events);
	};

	function handleContextMenu(event) {
		if (event.altKey)
			return (false);
		event.preventDefault();
		let menu = dayCtxMenuId;
		if (event.target.closest(".fc-daygrid-event-harness"))
			menu = eventCtxMenuId;
		let day = event.target.closest(".fc-daygrid-day")?.getAttribute("data-date");
		if (!day)
			return (false);
		let calEvId = event.target.closest(".fc-daygrid-event")?.getAttribute("data-event-id");
		show(event, {
			id: menu,
			props: {
				day,
				calEvent: getEvent(calEvId)
			}
		});
	}

	function handleEventClick(event) {
		if (props.handleEventClick)
			props.handleEventClick(event);
	}

	function getDayEvents(date) {
		let events = [];

		if (calRef) {
			let cal = calRef.current.getApi();
			events = cal.getEvents();

			if (events.length)
				events = events.filter(a => {
					let event_day = a.startStr.split("T")[0];
					if (date === event_day)
						return (a);
					event_day = a.endStr.split("T")[0];
					if (date === event_day)
						return (a);
					return (false);
				});
		}
		return (events);
	}

	function handleSelect(e) {
		props.handleAdd({
			DateStart: formatDate(e.start.setHours(8, 0, 0, 0)),
			DateEnd: formatDate(e.end.setHours(18, 0, 0, 0))
		});
	}

	function setActiveDisp(button) {
		button.parentElement.querySelectorAll(".fc-button").forEach(a => a.classList.remove("active"));
		button.classList.add("active");
	}

	function dispList(arg, button) {
		let calApi = calRef.current.getApi();
		let cur_disp = calApi.view.type;
		let btns = "listMonth,listWeek changeToGrid,changeToList";
		let new_disp = preg_replace(["dayGridMonth", "dayGridWeek"], ["listMonth", "listWeek"], cur_disp);

		calApi.changeView(new_disp);
		calApi.setOption("headerToolbar", {
			left: "prev,next,title",
			center: "",
			right: btns
		});
		setActiveDisp(button);
	}

	function dispGrid(arg, button) {
		let calApi = calRef.current.getApi();
		let cur_disp = calApi.view.type;
		let btns = "dayGridMonth,dayGridWeek changeToGrid,changeToList";
		let new_disp = preg_replace(["listMonth", "listWeek"], ["dayGridMonth", "dayGridWeek"], cur_disp);

		calApi.changeView(new_disp);
		calApi.setOption("headerToolbar", {
			left: "prev,next,title",
			center: "",
			right: btns
		});
		setActiveDisp(button);
	}

	const getEventsList = () => {
		if (!props.events?.length)
			return ([]);
		let ret = props.events.map((event) => {
			let readed_event = isReaded("NotViewedEvent", event.AnimationId);
			let color = event.Color;
			if (event.Category && event.Category[0])
				color = event.Category[0].DefaultHexaColor;
			return ({
				title: event.Title,
				start: event.DateStart,
				end: event.DateEnd,
				publicId: event.AnimationId,
				id: event.AnimationId,
				animationId: event.AnimationId,
				color: color,
				classNames: [(!readed_event ? "new-event" : "")],
				allDay: event.IsAllDay,
				...event
			});
		});
		return (ret);
	};

	const getEvent = (id) => {
		let api = calRef?.current?.getApi();
		if (!api || !id)
			return (false);
		return (api.getEventById(id));
	};

	const admins_props = (isAdmin() ? {
		selectable: true,
		selectMinDistance: 2,
		select: handleSelect
	} : {});

	return (
		<div className="calendar" id={props.calendarId} ref={cal_cont_ref} onContextMenu={handleContextMenu}>
			<FullCalendar
				ref={calRef}
				plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
				initialView={isMobile ? "listWeek" : "dayGridMonth"}
				contentHeight={isMobile ? "400px" : "auto"}
				locale={t("Code")}
				firstDay={1}
				eventDisplay="block"
				allDayText={t("Events.ALL_DAY")}
				headerToolbar={{
					left: "prev,next,title",
					center: "",
					right: isMobile ? "" : "dayGridMonth,dayGridWeek changeToGrid,changeToList"
				}}
				views={{
					dayGridMonth: {
						buttonText: t("Events.MONTH"),
						dayHeaderFormat: { weekday: "short" }
					},
					dayGridWeek: {
						buttonText: t("Events.WEEK"),
						dayHeaderFormat: { weekday: "short", day: "numeric" }
					},
					listMonth: {
						buttonText: t("Events.MONTH"),
						listDayFormat: { month: "long", weekday: "long", day: "numeric" },
						listDaySideFormat: false
					},
					listWeek: {
						buttonText: t("Events.WEEK"),
						listDayFormat: { month: "long", weekday: "long", day: "numeric" },
						listDaySideFormat: false
					}
				}}
				dateClick={(e) => { e.jsEvent.preventDefault(); handleDateClick(e); }}
				customButtons={{
					changeToList: {
						text: t("Events.LIST"),
						click: dispList
					},
					changeToGrid: {
						text: t("Events.GRID"),
						click: dispGrid
					}
				}}
				eventDidMount={(info) => {
					info.el.setAttribute("data-event-id", info.event.id);
				}}
				events={getEventsList()}
				eventClick={(e) => handleEventClick(e.event)}
				{...admins_props}
			/>
			{
				isAdmin()
				&&
				<>
					<CalendarCtxMenu forEvent={true} {...props} id={eventCtxMenuId} calendarRef={calRef} />
					<CalendarCtxMenu {...props} id={dayCtxMenuId} calendarRef={calRef} />
				</>
			}
		</div>
	);
}

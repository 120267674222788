import React, { useState, useEffect, useCallback, useRef } from "react";
import SitesTable from "./SitesTable/SitesTable";
import SitesTiles from "./SiteTiles/SiteTiles";
import genRequest from "includes/request";
import useAccount from "classes/Accounts/hooks/useAccount";

function SitesList(props) {
	const [displayed, setDisplayed] = useState([]);
	const [dispIndex, setDispIndex] = useState(0);
	const [dispStep] = useState(
		document.body.clientHeight < 1000 && document.body.clientWidth < 800 ? 10 : 20
	);
	const [hasMore, setHasMore] = useState(true);
	const [hasMoreDisplayed, setHasMoreDisplayed] = useState(true);

	useEffect(() => {
		setDisplayed(props.items.slice(0, dispIndex));
	}, [props.items]);

	const getNextItems = useCallback(() => {
		setDispIndex(prevIndex => {
			const newIndex = prevIndex + dispStep;
			const newDisplayed = props.items.slice(0, newIndex);
			setDisplayed(newDisplayed);
			// Si on a affiché tous les items disponibles et qu'il y en a potentiellement d'autres à charger
			if (newDisplayed.length >= props.items.length && props.hasMore) {
				props.fetchNextPage();
			}
			return newIndex;
		});
	}, [dispStep, props.items, props.hasMore, props.fetchNextPage]);

	useEffect(() => {
		setHasMoreDisplayed(displayed.length < props.items.length || props.hasMore);
	}, [displayed, props.items.length, props.hasMore]);

	const loaderRef = useRef(null);
	useEffect(() => {
		if (props.display === "table") return;
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasMoreDisplayed && !props.isFetchingNextPage) {
					getNextItems();
				}
			},
			{ threshold: 0.5 }
		);
		if (loaderRef.current) observer.observe(loaderRef.current);
		return () => observer.disconnect();
	}, [hasMoreDisplayed, getNextItems, props.isFetchingNextPage]);

	// useEffect(() => {
	// 	const old_sites = prevItems || [];
	// 	const new_sites = props.items || [];
	// 	const addeds = new_sites.filter(
	// 		(o1) => !old_sites.some((o2) => o1.SiteId === o2.SiteId)
	// 	);
	// 	const removed = old_sites.filter(
	// 		(o1) => !new_sites.some((o2) => o1.SiteId === o2.SiteId)
	// 	);

	// 	if (
	// 		prevStateProp !== props.state ||
	// 		addeds.length ||
	// 		removed.length ||
	// 		old_sites.length !== new_sites.length
	// 	) {
	// 		setSites(props.items);
	// 		setDisplayed([]);
	// 		setDispIndex(0);
	// 		setHasMore(!!props.items?.length);
	// 		getNextItems();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [props.items, props.state, getNextItems, prevItems, prevStateProp]);

	const childProps = {
		curSite: props.curSite,
		hasMore,
		handleItemClick: props.handleItemClick,
		getNextItems,
		search: props.search,
		checkbox: props.checkbox,
	};

	return (
		<div className="SitesList w-100">
			{props.display === "table" ? (
				<SitesTable
					displayed={props.tableItems}
					setSelected={props.setSelected}
					{...childProps}
				/>
			) : (
				<SitesTiles displayed={displayed} itemsLength={displayed?.length} {...childProps} />
			)}
			{/* Élément observer pour déclencher le chargement des éléments suivants */}
			<div ref={loaderRef} style={{ height: "100px" }} />
			{props.isFetchingNextPage && <div>Chargement de la suite...</div>}
		</div>
	);
}

export default SitesList;